import { useNavigate } from "react-router-dom";
import { CoinGeneralInfo, useCoinList } from "../../providers/CoinListProvider";
import './Spotlight.css';
import { format } from 'date-fns';
import { ethers } from 'ethers';
import { useEffect, useState } from "react";
import boomerApi from "../../requests/boomerApi";
import { getCroImage, getPercentageSold } from "../../utils/generalUtils";
import useWallet from "../../hooks/useWallet";

const Spotlight: React.FC = () => {

    const navigate = useNavigate();

    const { coinList } = useCoinList();

    const { chainId } = useWallet();

    const [spotlightCoin, setSpotlightCoin] = useState<CoinGeneralInfo | null>(null);
    const [lastSpotlightTimestamp, setLastSpotlightTimestamp] = useState<number>(0); // in seconds
    const [timeDiff, setTimeDiff] = useState<number>(0);

    const getCurrentTimeDifference = () => {
        // get current time in seconds
        const currentTime = Math.floor(Date.now() / 1000);
        const difference = currentTime - lastSpotlightTimestamp;
        // console.log('Current time: ', currentTime);
        // console.log('lastSpotlightTimestamp: ', lastSpotlightTimestamp);
        // console.log(`Current time difference: ${difference}`);
        return difference;
    };

    const coinNotFound: CoinGeneralInfo = {
        coinAddress: 'N/A',
        creatorAddress: 'N/A',
        metaURI: '',
        lastUpdated: '0',
        dateCreated: '',
        hasGraduated: false,
        fundsAddress: '',
        fundsCoinBalance: '0',
        fundsEthBalance: '0',
        metadata: {
            name: 'N/A',
            ticker: 'N/A',
            description: 'N/A',
            imageURL: '',
        },
        puushdotfunVersion: 'N/A',
        chainId: 'N/A'
    }

    useEffect(() => {
        const fetchSpotlightCoin = async () => {
            try {
                const spotlightData = await boomerApi.getSpotlightCoin(chainId ? chainId : 25);
                const spotlightCoin = coinList.find(coin => coin.coinAddress === spotlightData.coinAddress);
                if (!spotlightCoin) {
                    setSpotlightCoin(coinNotFound);
                } else {
                    setSpotlightCoin(spotlightCoin);
                    setLastSpotlightTimestamp(spotlightData.lastTimestamp);
                }
            } catch (error) {
                console.error('Error fetching spotlight coin:', error);
            }
        };

        fetchSpotlightCoin(); // Initial fetch

        const interval = setInterval(fetchSpotlightCoin, 10000); // Fetch every 10 seconds
        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [coinList, chainId]);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeDiff(getCurrentTimeDifference());
        }, 1000);
        return () => clearInterval(interval);
    }, [lastSpotlightTimestamp]);

    if (!spotlightCoin) {
        return null;
    }

    const navToCoin = (coinAddress: string) => {
        navigate(`/coin/${coinAddress}`);
    };

    const openAddressExplorer = (event: React.MouseEvent<HTMLDivElement>, address: string) => {
        event.stopPropagation();
        window.open(`https://cronoscan.com/address/${address}`, '_blank');
    }

    // Format the lastUpdated date to a more human-readable format
    const formattedLastUpdated = format(new Date(spotlightCoin.lastUpdated), 'PPPpp');

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}m ${remainingSeconds}s`;
    };

    return (
        <div className="Spotlight-container">
            {/* <img className='Spotlight-title' src={'https://puushdabutton-web-images.s3.amazonaws.com/Spotlight.png'} alt='' /> */}
            <img className='Spotlight-title' src={'https://puushdabutton-web-images.s3.amazonaws.com/spot.gif'} alt='' />
            <div className="Spotlight-item clicky" onClick={() => navToCoin(spotlightCoin.coinAddress)}>
                <div className="Spotlight-image-container">
                    <img className='Spotlight-image' src={spotlightCoin.metadata?.imageURL} alt="" />
                </div>
                {
                    spotlightCoin.coinAddress !== 'N/A' && (
                        <div className="Spotlight-info">
                            <div
                                className='Spotlight-createdBy'
                                onClick={(event) => openAddressExplorer(event, spotlightCoin.creatorAddress)}
                            >
                                {getCroImage(spotlightCoin.chainId)}Created by {spotlightCoin.creatorAddress.slice(-6)}</div>
                            <div className='Spotlight-updatedAt'>Updated {formattedLastUpdated}</div>
                            <div className='Spotlight-coinsForSale'>{getPercentageSold(spotlightCoin.fundsCoinBalance, spotlightCoin.puushdotfunVersion)}% SOLD</div>
                            <div className='Spotlight-description'>
                                {spotlightCoin.metadata?.name} ({spotlightCoin.metadata?.ticker})
                            </div>
                        </div>
                    )
                }
            </div>
            {
                getCurrentTimeDifference() < 900
                    ? <button
                        className="Spotlight-button locked text-white"
                    >
                        Locked: {formatTime(900 - getCurrentTimeDifference())}
                    </button>
                    : <button
                        className="Spotlight-button clicky"
                        onClick={() => navigate(`/buy-spotlight`)}
                    >
                        {
                            chainId ? <div className="full-row middle gapped">BUY SPOTLIGHT 100{getCroImage(chainId)}</div> : 'BUY SPOTLIGHT'
                        }
                    </button>
            }
        </div>
    );
};

export default Spotlight;