// FILE: src/providers/CoinListProvider.tsx
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useWebSocket, WebSocketContextType } from './WebSocketProvider';

export type CoinMetadataForS3 = {
    name: string; // Name of coin
    ticker: string; // Ticker of coin
    description: string; // Description of coin
    imageURL: string; // URL to image
    twitter?: string; // Link to twitter
    telegram?: string; // Link to telegram
    website?: string; // Link to website
    nsfwScore?: number; // NSFW score of the image 
};

export interface CoinGeneralInfo {
    coinAddress: string;
    creatorAddress: string;
    metaURI: string;
    lastUpdated: string;
    dateCreated: string;
    hasGraduated: boolean;
    fundsAddress: string;
    fundsCoinBalance: string;
    fundsEthBalance: string;
    metadata?: CoinMetadataForS3;
    puushdotfunVersion: string;
    chainId: string;
}

export interface TransactionLog {
    coinAddress: string;
    userAddress: string;
    txHash: string;
    transactionType: string;
    ethAmount: string;
    coinAmount: string;
    ethBalance: string;
    coinBalance: string;
    lastUpdated: string;
    ethFeeAmount: string;
    chainId: string;
}

export type CandleData = {
    time: number;
    open: number;
    high: number;
    low: number;
    close: number;
};

export type AllCandleData = {
    fiveMinute: CandleData[];
    hourly: CandleData[];
    fourHourly: CandleData[];
    daily: CandleData[];
};

interface CoinListProviderProps {
    children: ReactNode;
}

interface CoinListContextType {
    coinList: CoinGeneralInfo[];
    setCoinList: React.Dispatch<React.SetStateAction<CoinGeneralInfo[]>>;
    getCoinByAddress: (address: string) => CoinGeneralInfo | undefined;
    txLogs: TransactionLog[];
    candleData: AllCandleData;
}

const CoinListContext = createContext<CoinListContextType | undefined>(undefined);

export const CoinListProvider: React.FC<CoinListProviderProps> = ({ children }) => {
    const { socket, joinCoinRoom, leaveCoinRoom } = useWebSocket() as WebSocketContextType;
    const [coinList, setCoinList] = useState<CoinGeneralInfo[]>([]);
    const [txLogs, setTxLogs] = useState<TransactionLog[]>([]);
    const [candleData, setCandleData] = useState<AllCandleData>({ fiveMinute: [], hourly: [], fourHourly: [], daily: [] });

    useEffect(() => {
        if (socket) {
            socket.on('coinListUpdate', (data: CoinGeneralInfo[]) => {
                setCoinList(data);
                console.log('Coin list updated:', data);
            });

            socket.on('txLogsUpdate', (data: TransactionLog[]) => {
                setTxLogs(data);
                console.log('Transaction logs updated:', data);
            });

            socket.on('candleDataUpdate', (data: AllCandleData) => {
                setCandleData(data);
                console.log('Candle data updated:', data);
            });

            return () => {
                socket.off('coinListUpdate');
                socket.off('txLogsUpdate');
                socket.off('candleDataUpdate');
            };
        }
    }, [socket]);

    const getCoinByAddress = (address: string): CoinGeneralInfo | undefined => {
        return coinList.find(coin => coin.coinAddress === address);
    };

    return (
        <CoinListContext.Provider value={{ coinList, setCoinList, getCoinByAddress, txLogs, candleData }}>
            {children}
        </CoinListContext.Provider>
    );
};

export const useCoinList = () => {
    const context = useContext(CoinListContext);
    if (context === undefined) {
        throw new Error('useCoinList must be used within a CoinListProvider');
    }
    return context;
};
