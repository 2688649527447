import { ethers } from 'ethers';
import PuushdotLiquidityV103 from '../contracts/PuushdotLiquidityV103.json';

class PublicPuushdotLiquidityV103Instance {
    private contract: any;
    public contractAddress: string;

    constructor(contract: any, contractAddress: string) {
        this.contract = contract;
        this.contractAddress = contractAddress;
    }

    public static async createInstance(provider: ethers.JsonRpcProvider, contractAddress: string): Promise<PublicPuushdotLiquidityV103Instance> {
        const contract = new ethers.Contract(contractAddress, PuushdotLiquidityV103.abi, provider);
        return new PublicPuushdotLiquidityV103Instance(contract, contractAddress);
    }

    public async viewCoinTexts(coinAddress: string): Promise<any> {
        try {
            return await this.contract.coinTexts(coinAddress);
        } catch (error) {
            console.error('Error getting coin texts:', error);
            throw error;
        }
    };

    public async viewCoinRouterId(coinAddress: string): Promise<bigint> {
        try {
            return await this.contract.coinRouterId(coinAddress);
        } catch (error) {
            console.error('Error getting router ID:', error);
            throw error;
        };
    };

    public async viewRouter(id: string): Promise<string> {
        try {
            return await this.contract.routers(id);
        } catch (error) {
            console.error('Error routers:', error);
            throw error;
        }
    };

    public async viewTimeLimit(): Promise<string> {
        try {
            return await this.contract.timeLimit();
        } catch (error) {
            console.error('Error getting time limit:', error);
            throw error;
        }
    };
}

export default PublicPuushdotLiquidityV103Instance;
