type CustomCoinProps = {
    url: string | undefined;
    size?: number
};

const CustomCoin: React.FC<CustomCoinProps> = ({ url, size = 35 }) => {

    const style = {
        height: `${size}px`,
        width: `${size}px`,
        borderRadius: '50%',
        border: '1px solid #11111190',
        backgroundColor: '#11111190',
    }

    const placeHolder = 'https://via.placeholder.com/000';

    return (
        <img className={`CustomIcon`} src={url ? url : placeHolder} style={style} alt='' />
    );
}
export default CustomCoin;