import React from 'react';
import './ProgressBar.css';
import { useGlobalInfoContext } from '../../providers/GlobalInfoProvider';
import { CoinGeneralInfo } from '../../providers/CoinListProvider';
import { getPercentageSold } from '../../utils/generalUtils';
import { toEth, toWei } from '../../utils/priceUtils';
import Decimal from 'decimal.js';

interface ProgressBarProps {
    coin: CoinGeneralInfo; // Progress value between 0 and 100
}

const ProgressBar: React.FC<ProgressBarProps> = ({ coin }) => {

    const { globalInfo, loading, error } = useGlobalInfoContext();

    const estimateMarketCap = (): string => {
        if (globalInfo && !loading && !error) {
            const totalSupply = new Decimal(toWei('1000000000'));
            const coinFundsBalance = new Decimal(coin.fundsCoinBalance);
            const ethFundsBalance = new Decimal(coin.fundsEthBalance);
            // Find out how much 1 coin is worth in ETH, use Decimal.js
            const coinPriceInEth = ethFundsBalance.div(coinFundsBalance);
            // Find out how much 1 coin is worth in USD, use Decimal.js
            const coinPriceInUsd = coinPriceInEth.mul(globalInfo.CROUSD);
            // Find out the market cap of the coin, use Decimal.js
            const marketCap = coinPriceInUsd.mul(totalSupply).div(toWei('1'));

            return marketCap.toFixed(2);
        } else {
            return 'loading...';
        }
    };

    const displayNumberString = (num: string): string => {
        return Number(num).toLocaleString('en-US');
    };

    return (
        <div className='ProgressBar'>
            <div className='full-row' style={{ gap: '10px', justifyContent: 'center' }}>
                <div className="progress-bar-text text-green"><span className='small-text'>{Number(getPercentageSold(coin.fundsCoinBalance, coin.puushdotfunVersion))}% </span><span className='small-text text-green-dark'>SOLD</span></div>
                <div className="progress-bar-text text-orange"><span className='small-text'>{displayNumberString(estimateMarketCap())} $USD </span><span className='small-text text-orange-dark'>Market Cap</span></div>
            </div>

            <div className="progress-bar-container">
                <div className="progress-bar">
                    <div
                        className="progress-bar-fill"
                        style={{ width: `${Number(getPercentageSold(coin.fundsCoinBalance, coin.puushdotfunVersion))}%` }}
                    ></div>
                </div>
            </div>
        </div>
    );
};

export default ProgressBar;
