import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useWallet from "../../hooks/useWallet";
import { CoinGeneralInfo } from "../../providers/CoinListProvider";
import { displayDate, formatTime } from "../../utils/generalUtils";
import useLoadingWithDots from "../../hooks/useLoadingWithDots";
import { routerAddressName } from "../../utils/graduationUtils";

type CreatorPanelProps = {
    coin: CoinGeneralInfo;
};

const CreatorPanel: React.FC<CreatorPanelProps> = ({ coin }) => {

    const [visible, setVisible] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState(0);
    const [loading, setLoading] = useState(false);

    const [currentGraduationPlatformAddress, setCurrentGraduationPlatformAddress] = useState('');
    const [currentCoinText, setCurrentCoinText] = useState('');

    const [platformSelectValue, setPlatformSelectValue] = useState(0);
    const [coinTextInput, setCoinTextInput] = useState('');

    const {
        account,
        puushdotLiquidityV103Instance,
        puushdotV103Instance,
        chainId,
    } = useWallet();

    //Decrease time remaining by 1 second every second unless it is 0
    useEffect(() => {
        const interval = setInterval(() => {
            if (timeRemaining > 0) {
                setTimeRemaining(timeRemaining - 1);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [timeRemaining]);

    useEffect(() => {
        if (account && (account.toLowerCase() === coin.creatorAddress.toLowerCase()) && chainId?.toString() === coin.chainId) {
            setVisible(true);
        }
    }, [account, coin]);

    const checkTimeRemaining = async () => {
        if (puushdotV103Instance && puushdotLiquidityV103Instance) {
            const coinCreateTimestamp: string = await puushdotV103Instance.viewCoinCreateTimestamp(coin.coinAddress);
            const timeLimit: string = await puushdotLiquidityV103Instance.viewTimeLimit();
            const timeRemainingSeconds = Math.max(parseInt(timeLimit) - (Date.now() / 1000 - parseInt(coinCreateTimestamp)), 0);
            setTimeRemaining(timeRemainingSeconds);
        }
    };

    const checkInfo = async () => {
        if (puushdotLiquidityV103Instance) {
            const coinTexts: string = await puushdotLiquidityV103Instance.viewCoinTexts(coin.coinAddress);
            const coinRouterId: bigint = await puushdotLiquidityV103Instance.viewCoinRouterId(coin.coinAddress);
            const coinRouterAddress = await puushdotLiquidityV103Instance.viewRouter(coinRouterId.toString());
            setCurrentCoinText(coinTexts);
            setCurrentGraduationPlatformAddress(coinRouterAddress);
        }
    };

    // Check time remaining and info on load and every minute
    useEffect(() => {
        const interval = setInterval(() => {
            checkTimeRemaining();
            checkInfo();
        }, 60000);
        return () => clearInterval(interval);
    }, [puushdotLiquidityV103Instance, puushdotV103Instance]);

    useEffect(() => {
        if (puushdotV103Instance && puushdotLiquidityV103Instance) {
            checkTimeRemaining();
            checkInfo();
        };
    }, [puushdotV103Instance, puushdotLiquidityV103Instance]);

    const handleSubmitRouter = async () => {
        setLoading(true);
        if (puushdotLiquidityV103Instance) {
            try {
                const tx = await puushdotLiquidityV103Instance.setCoinGraduationRouterAsCoinCreator(coin.coinAddress, platformSelectValue)
                await tx.wait();
                // Get new info
                await checkInfo();
                setLoading(false);
            } catch (error) {
                console.error('Error setting graduation router:', error);
                setLoading(false);
            }
        }
    };

    const handleSubmitText = async () => {
        setLoading(true);
        if (puushdotLiquidityV103Instance) {
            try {
                const tx = await puushdotLiquidityV103Instance.setCoinTextAsCoinCreator(coin.coinAddress, coinTextInput);
                await tx.wait();
                // Get new info
                await checkInfo();
            } catch (error) {
                console.error('Error setting text:', error);
                setLoading(false);
            }
        }
        setLoading(false);
    };

    const disabled = loading || timeRemaining === 0;

    return (
        <>
            {
                (visible === true) &&
                <div className="CoinGraduationInfo">
                    <div className='med-text' style={{ fontWeight: '600' }}>Creator Panel</div>
                    <div className='text-orange small-text'>
                        You have
                        <span className='text-white small-text'>{' '}{formatTime(timeRemaining)}{' '}</span>
                        remaining to change the graduation platform and/or extra info of your coin.
                    </div>
                    <div className='text-orange small-text'>
                        Graduation Settings will update within 5 minutes.
                    </div>
                    <div className="CoinGraduationInfo-item">
                        <div className="small-text text-white">Current graduation platform</div>
                        <div className="smed-text text-whitewhite">{routerAddressName[currentGraduationPlatformAddress] || 'Error'}</div>
                    </div>
                    <div className="CoinGraduationInfo-item">
                        <div className="small-text text-white">Select new platform</div>
                        <RouterSelectMenu chainId={chainId} disabled={disabled} platformSelectValue={platformSelectValue} setPlatformSelectValue={setPlatformSelectValue} />
                        <button disabled={disabled} onClick={() => handleSubmitRouter()}>submit</button>
                        <div className="small-text text-white">{platformSelectValue === 9 && '⚠️Contact support on Discord for Manual request approval.'}</div>
                    </div>
                    <div className="CoinGraduationInfo-item">
                        <div className="small-text text-white">Current Extra Info</div>
                        <div className="smed-text text-whitewhite">{currentCoinText || 'None'}</div>
                    </div>
                    <div className="CoinGraduationInfo-item">
                        <div className='text-white small-text'>
                            Enter extra info (Token pair, Ect..):
                        </div>
                        <input disabled={disabled} type="text" placeholder="Extra Info..." value={coinTextInput} onChange={(e) => setCoinTextInput(e.target.value)} />
                        <button disabled={disabled} onClick={() => handleSubmitText()}>submit</button>
                    </div>
                </div>
            }
        </>
    )
};

const RouterSelectMenu: React.FC<{
    chainId: number | undefined,
    disabled: boolean,
    platformSelectValue: number,
    setPlatformSelectValue: Dispatch<SetStateAction<number>>
}> = ({ chainId, disabled, platformSelectValue, setPlatformSelectValue }) => {
    switch (chainId) {
        case 25:
            return (
                <select disabled={disabled} value={platformSelectValue} onChange={(e) => setPlatformSelectValue(parseInt(e.target.value))}>
                    <option value='0'>VVS Finance *CRO pair</option>
                    <option value='1'>Ebisu's Bay *CRO pair</option>
                    <option value='9'>Manual *Other pair request</option>
                </select>
            )
        case 388:
            return (
                <select disabled={disabled} value={platformSelectValue} onChange={(e) => setPlatformSelectValue(parseInt(e.target.value))}>
                    <option value='0'>H2 Finance *ZKCRO pair</option>
                    <option value='1'>Ebisu's Bay *ZKCRO pair</option>
                    <option value='2'>Marswap *ZKCRO pair</option>
                    <option value='9'>Manual *Other pair request</option>
                </select>
            )
        default:
            return <div>Unknown chain</div>;
    };
};

export default CreatorPanel;