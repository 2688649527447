import React, { createContext, useContext, ReactNode } from 'react';
import { useGlobalInfo } from '../hooks/useGlobalInfo';

interface GlobalInfoContextProps {
    globalInfo: { PUUSHBurned: string; CROUSD: number } | null;
    loading: boolean;
    error: string | null;
}

const GlobalInfoContext = createContext<GlobalInfoContextProps | undefined>(undefined);

export const GlobalInfoProvider = ({ children }: { children: ReactNode }) => {
    const { globalInfo, loading, error } = useGlobalInfo();

    return (
        <GlobalInfoContext.Provider value={{ globalInfo, loading, error }}>
            {children}
        </GlobalInfoContext.Provider>
    );
};

export const useGlobalInfoContext = () => {
    const context = useContext(GlobalInfoContext);
    if (!context) {
        throw new Error('useGlobalInfoContext must be used within a GlobalInfoProvider');
    }
    return context;
};
