import { ethers } from 'ethers';
import { format } from 'date-fns';
import config from '../config';
import CustomCoin from '../components/CustomCoin/CustomCoin';


export const displayName = (userAddress: string): string => {
    return userAddress.slice(-6).toLowerCase();
}

export const getPercentageSold = (fundsCoinBalanceWei: string, contractVersion: string): string => {
    switch (contractVersion) {
        default:
        case config.PUUSHDOTFUN_V102_CONTRACT_ADDRESS.toLowerCase():
        case config.PUUSHDOTFUN_V103_CONTRACT_ADDRESS.toLowerCase():
        case config.PUUSHDOTFUN_V103_CONTRACT_ADDRESS_CRONOSZKEVM.toLowerCase():
            return ((1 - (Number(ethers.formatEther(fundsCoinBalanceWei)) - 350_000_000) / 650_000_000) * 100).toFixed(2) || 'UNKN';
        case config.PUUSHDOTFUN_CONTRACT_ADDRESS.toLowerCase():
        case config.PUUSHDOTFUN_V101_CONTRACT_ADDRESS.toLowerCase():
            return ((1 - (Number(ethers.formatEther(fundsCoinBalanceWei)) - 200_000_000) / 800_000_000) * 100).toFixed(2) || 'UNKN';

    };
}

export const displayDate = (date: string): string => {
    return format(new Date(date), 'PPPpp');
}

export const getCroImage = (chainId: number | undefined | string) => {
    switch (chainId?.toString()) {
        case '25':
            return <CustomCoin url={'https://s3.coinmarketcap.com/static-gravity/image/84e1ec1257dd4a6da6ee5584338a2460.jpeg'} size={17} />;
        case '388':
            return <CustomCoin url={'https://puushdabutton-web-images.s3.amazonaws.com/Coin_namezkCRO.png'} size={17} />;
        default:
            return <></>
    };
};

/**
 * Converts a Unix timestamp (in seconds) into a local timestamp string.
 * @param unixTimestamp - The Unix timestamp in seconds.
 * @returns A local timestamp string in the format 'YYYY-MM-DD HH:mm:ss'.
 */
export function displayDateUnix(unixTimestamp: string): string {
    // Create a Date object using the input Unix timestamp (converted to milliseconds)
    const date = new Date(parseFloat(unixTimestamp) * 1000);

    // Get the components of the date
    const year = String(date.getFullYear()).slice(-2); // Last two digits of the year
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Format the timestamp string
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

export const openAddressExplorer = (event: React.MouseEvent<HTMLDivElement | HTMLSpanElement>, address: string, chainId: string | undefined) => {
    event.stopPropagation();
    switch (chainId) {
        case '25':
        default:
            window.open(`https://cronoscan.com/address/${address}`, '_blank');
            return;
        case '388':
            window.open(`https://explorer.zkevm.cronos.org/address/${address}`, '_blank');
            return;

    };
}
export const openTxExplorer = (event: React.MouseEvent<HTMLDivElement | HTMLSpanElement>, address: string, chainId: string | undefined) => {
    event.stopPropagation();
    switch (chainId) {
        case '25':
        default:
            window.open(`https://cronoscan.com/tx/${address}`, '_blank');
            return;
        case '388':
            window.open(`https://explorer.zkevm.cronos.org/tx/${address}`, '_blank');
            return;
    };
}

// https://explorer.zkevm.cronos.org/tx/0xc10cc62379d493422b88e61694c0eb42ac6915fb46ec7150476243ec75afb00a

export const openURLInNew = (event: React.MouseEvent<HTMLDivElement | HTMLSpanElement>, url: string | undefined) => {
    if (!url) return;
    event.stopPropagation();
    window.open(`${url}`, '_blank');
}

// Utility function to shorten URLs
export const shortenUrl = (url: string | undefined, maxLength: number = 25): string => {
    if (!url) return '';
    if (url.length <= maxLength) {
        return url;
    }
    return url.slice(0, maxLength) + '...';
};

// Hours, minutes, seconds
export const formatTime = (seconds: number) => {

    const Hours = Math.floor(seconds / 3600);
    const Minutes = Math.floor((seconds % 3600) / 60);
    const Seconds = Math.floor(seconds % 60);

    return `${Hours}h ${Minutes}m ${Seconds}s`;
};