import { AllCandleData, CandleData, CoinGeneralInfo } from "../../providers/CoinListProvider";
import { displayDate, displayName, getPercentageSold, openAddressExplorer, openURLInNew } from '../../utils/generalUtils';
import { ColorType, createChart, CrosshairMode, PriceScaleMode, UTCTimestamp } from 'lightweight-charts';
import React, { useEffect, useRef, useState } from 'react';
import BuySellSection from "./BuySellSection";
import { formatDate } from "date-fns";

type GraphSectionProps = {
    coin: CoinGeneralInfo;
    allCandleData: AllCandleData;
};

const GraphSection: React.FC<GraphSectionProps> = ({ coin, allCandleData }) => {
    const chartContainerRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<any>(null);
    const candlestickSeriesRef = useRef<any>(null);
    const [interval, setInterval] = useState<keyof AllCandleData>('hourly');
    const [initialLoad, setInitialLoad] = useState(true);

    const chartOptions = {
        layout: { textColor: 'white', background: { type: 'solid' as ColorType.Solid, color: '#1d2128' } },
        grid: {
            vertLines: {
                color: '#282c34',
            },
            horzLines: {
                color: '#282c34',
            },
        },
        crossHair: {
            mode: CrosshairMode.Normal,
        },
        rightPriceScale: {
            borderVisible: false,
            mode: PriceScaleMode.Logarithmic,
        },
        timeScale: {
            borderVisible: false,
            timeVisible: true,
            secondsVisible: false,
            rightOffset: 2,  // Adjust right offset
        },
    };

    const candleOptions = {
        upColor: '#26a69a',
        downColor: '#ef5350',
        borderVisible: false,
        wickUpColor: '#26a69a',
        wickDownColor: '#ef5350',
        priceFormat: {
            type: 'price' as any,
            precision: 8 as any,
            minMove: 0.00000001,
        },
    };

    const getInitialVisibleTimeRange = (latestTime: UTCTimestamp, interval: keyof AllCandleData): { from: UTCTimestamp, to: UTCTimestamp } => {
        switch (interval) {
            case 'fiveMinute':
                return { from: latestTime - (60 * 60 * 24 * 1) as UTCTimestamp, to: latestTime }; // 1 day
            case 'hourly':
                return { from: latestTime - (60 * 60 * 24 * 3) as UTCTimestamp, to: latestTime }; // 3 days
            case 'fourHourly':
                return { from: latestTime - (60 * 60 * 24 * 10) as UTCTimestamp, to: latestTime }; // 10 days
            case 'daily':
                return { from: latestTime - (60 * 60 * 24 * 60) as UTCTimestamp, to: latestTime }; // 60 days
            default:
                return { from: latestTime - (60 * 60 * 24 * 3) as UTCTimestamp, to: latestTime }; // Default to 3 days for hourly
        }
    };

    const formatCandleData = (candleData: CandleData[]) => {
        // Ensure correct time format for lightweight-charts
        const formattedCandleData = candleData
            .map(candle => ({
                ...candle,
                time: Math.floor(candle.time) as UTCTimestamp, // Ensure time is in seconds
            }))
            .sort((a, b) => a.time - b.time); // Ensure data is sorted by time

        return formattedCandleData
    };

    useEffect(() => {
        if (chartContainerRef.current) {
            const chart = createChart(chartContainerRef.current, chartOptions);
            chartRef.current = chart;

            const candlestickSeries = chart.addCandlestickSeries(candleOptions);
            candlestickSeriesRef.current = candlestickSeries;

            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    requestAnimationFrame(() => {
                        const { width, height } = entry.contentRect;
                        chart.applyOptions({ width, height });
                    });
                }
            });

            resizeObserver.observe(chartContainerRef.current);

            return () => {
                resizeObserver.disconnect();
                chart.remove();
            };
        }
    }, []);

    useEffect(() => {
        if (chartRef.current && candlestickSeriesRef.current) {
            const chart = chartRef.current;
            const candlestickSeries = candlestickSeriesRef.current;

            const candleStickData = formatCandleData(allCandleData[interval]);
            candlestickSeries.setData(candleStickData);

            const lastCandle = candleStickData[candleStickData.length - 1];
            if (lastCandle && initialLoad) {
                const initialRange = getInitialVisibleTimeRange(lastCandle.time, interval);
                if (initialRange && initialRange.from && initialRange.to) {
                    console.log('Setting visible range:', initialRange);
                    chart.timeScale().setVisibleRange(initialRange);
                    chart.timeScale().scrollToRealTime();
                    setInitialLoad(false);
                }
            }
        }
    }, [allCandleData, interval]);

    return (
        <div className='GraphSection'>
            <div className='full-row-wrap GraphSection-info'>
                <div className='text-white'>
                    {coin.metadata?.name}
                </div>
                <div className='text-white'>
                    ({coin.metadata?.ticker})
                </div>
                <div className='text-green'>
                    {getPercentageSold(coin.fundsCoinBalance, coin.puushdotfunVersion)}% SOLD
                </div>
                <div className='text-blue text-clicky' onClick={(e) => openAddressExplorer(e, coin.creatorAddress, coin.chainId)}>{'Created by '}{displayName(coin.creatorAddress)}</div>
                <div className='text-clicky text-purple' onClick={(e) => openAddressExplorer(e, coin.coinAddress, coin.chainId)}>{'Coin address '}{coin.coinAddress}</div>
            </div>
            <div className='full-row-wrap GraphSection-info'>
                <div className='text-white'>
                    last update {displayDate(coin.lastUpdated)}
                </div>
                <div className='text-clicky' onClick={(e) => openURLInNew(e, `https://wolfswap.app/swap?chainId=${coin.chainId}&sellToken=0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE&buyToken=${coin.coinAddress}`)}>
                    {'[Wolfswap]'}
                </div>
                <div className='text-clicky' onClick={(e) => openURLInNew(e, `https://dexscreener.com/cronos/${coin.coinAddress}`)}>
                    {'[Dexscreener]'}
                </div>
            </div>
            <div className="full-row" style={{ justifyContent: 'flex-start', gap: '5px' }}>
                <button className={`s-button disabled ${interval === 'fiveMinute' ? 'active' : ''}`} onClick={() => setInterval('hourly')}>5m</button>
                <button className={`s-button ${interval === 'hourly' ? 'active' : ''}`} onClick={() => setInterval('hourly')}>1h</button>
                <button className={`s-button disabled ${interval === 'fourHourly' ? 'active' : ''}`} onClick={() => setInterval('hourly')}>4h</button>
                <button className={`s-button disabled ${interval === 'daily' ? 'active' : ''}`} onClick={() => setInterval('hourly')}>1d</button>
            </div>
            <div className='GraphSection-tools'>
                <div ref={chartContainerRef} className="GraphSection-chart"></div>
                {
                    coin.hasGraduated ?
                        <>
                            <div className='GraphSection-graduated text-white smed-text' style={{ width: '350px', minWidth: '350px' }}>
                                <div className='full-row text-whitewhite' style={{ marginBottom: '10px' }}>
                                    {coin.metadata?.name} has graduated!
                                </div>
                                <div className='text-white'>
                                    <div>You can now find the coin on</div>
                                    <div className='text-clicky text-whitewhite large-text' style={{ margin: '10px 0px' }} onClick={(e) => openURLInNew(e, `https://wolfswap.app/swap?chainId=${coin.chainId}&sellToken=0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE&buyToken=${coin.coinAddress}`)}>
                                        {'[Wolfswap]'}
                                    </div>
                                    <div>If the coin is set to manual graduation, please open a ticket on discord to inform the team!</div>
                                </div>
                            </div>
                        </> :
                        <>
                            <BuySellSection coin={coin} />
                        </>
                }
            </div>
        </div>
    );
};

export default GraphSection;
