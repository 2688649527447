import React, { useEffect, useState } from 'react';
import './CoinGraduationInfo.css';
import { CoinGeneralInfo } from '../../providers/CoinListProvider';
import usePublicReads from '../../hooks/usePublicReads';
import config from '../../config';
import PublicPuushdotLiquidityV101Instance from '../../web3/publicInstances/publicPuushdotLiquidityV101Instance';
import PublicPuushdotLiquidityV103Instance from '../../web3/publicInstances/publicPuushdotLiquidityV103Instance';
import { routerAddressName } from '../../utils/graduationUtils';

type CoinGraduationInfoProps = {
    coin: CoinGeneralInfo;
};

const CoinGraduationInfo: React.FC<CoinGraduationInfoProps> = ({ coin }) => {

    const [coinText, setCoinText] = useState<string>('');
    const [routerAddress, setRouterAddress] = useState<string>('');

    const {
        publicLiquidityV101Instance,
        publicLiquidityV103Instance,
    } = usePublicReads(parseInt(coin.chainId));

    const getInstance = (version: string) => {
        switch (version) {
            case 'v1':
            case config.PUUSHDOTFUN_CONTRACT_ADDRESS.toLowerCase():
            case config.PUUSHDOTFUN_V101_CONTRACT_ADDRESS.toLowerCase():
            case config.PUUSHDOTFUN_V102_CONTRACT_ADDRESS.toLowerCase():
                return publicLiquidityV101Instance;
            case config.PUUSHDOTFUN_V103_CONTRACT_ADDRESS.toLowerCase():
            case config.PUUSHDOTFUN_V103_CONTRACT_ADDRESS_CRONOSZKEVM.toLowerCase():
            default:
                return publicLiquidityV103Instance;
        }
    };

    const init = async (coinAddress: string, version: string) => {
        const instance = getInstance(version);
        if (!instance) {
            console.log(`We returned because no isntance found`)
            return;
        }

        const [routerAddress, coinText] = await Promise.all([
            getRouterAddress(instance),
            instance.viewCoinTexts(coinAddress),
        ]);

        setRouterAddress(routerAddress);
        setCoinText(coinText);
    };

    const getRouterAddress = async (instance: PublicPuushdotLiquidityV101Instance | PublicPuushdotLiquidityV103Instance) => {
        if (instance instanceof PublicPuushdotLiquidityV101Instance) {
            return instance.viewRouter(coin.coinAddress);
        } else if (instance instanceof PublicPuushdotLiquidityV103Instance) {
            const routerId = await instance.viewCoinRouterId(coin.coinAddress)
            return instance.viewRouter(routerId.toString());
        } else {
            return 'Unknown';
        }
    };

    useEffect(() => {
        if (!publicLiquidityV101Instance && !publicLiquidityV103Instance) return;
        init(coin.coinAddress, coin.puushdotfunVersion);
        // Refresh every minute
        const interval = setInterval(() => {
            init(coin.coinAddress, coin.puushdotfunVersion);
        }, 60000);
        return () => clearInterval(interval);
    }, [publicLiquidityV101Instance, publicLiquidityV103Instance]);

    return (
        <div className="CoinGraduationInfo">
            <div className='med-text' style={{ fontWeight: '600' }}>Graduation Settings</div>
            <div className='text-white small-text'>Refreshes every 1 min.</div>
            <div className="CoinGraduationInfo-item">
                <div className='text-orange small-text'>Creator can change graduation settings within 12 hours of coin creation.</div>
            </div>
            <div className="CoinGraduationInfo-item">
                <div className="small-text text-white">Platform</div>
                <div className="smed-text">{routerAddressName[routerAddress]}</div>
            </div>
            <div className="CoinGraduationInfo-item">
                <div className="small-text text-white">Router Address</div>
                <div className="small-text">{routerAddress !== '0x0000000000000000000000000000000000000000' ? routerAddress : 'Manual'}</div>
            </div>
            <div className="CoinGraduationInfo-item">
                <div className="small-text text-white">Extra Info (Creator Request)</div>
                <div className="small-text">{coinText || 'None'}</div>
            </div>
        </div>
    );
};

export default CoinGraduationInfo;
