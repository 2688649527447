interface Config {
    BASE_API_URL: string;
    BASE_WS_URL: string;
    CHAT_BASE_HTTP_URL: string;
    CHAT_BASE_WS_URL: string;
    PUUSHDOTFUN_CONTRACT_ADDRESS: string;
    PUUSHDOTFUN_V101_CONTRACT_ADDRESS: string;
    PUUSHDOTFUN_V102_CONTRACT_ADDRESS: string;
    PUUSHDOTFUN_V103_CONTRACT_ADDRESS: string;
    PUUSHDOTFUN_V103_CONTRACT_ADDRESS_CRONOSZKEVM: string;
    PUUSHDOTLIQUIDITYV101_CONTRACT_ADDRESS: string;
    PUUSHDOTLIQUIDITYV103_CONTRACT_ADDRESS: string;
    PUUSHDOTLIQUIDITYV103_CONTRACT_ADDRESS_CRONOSZKEVM: string;
    SPOTLIGHT_CONTRACT_ADDRESS: string
    PUUSHDOTWIN_CONTRACT_ADDRESS: string;
    PUUSHDOTSPOTLIGHT_CRONOSZKEVM: string,
    prefix: string;
}

interface ConfigMap {
    [key: string]: Config;
}

const devConfig: Config = {
    BASE_API_URL: 'https://api.testpuush.fun',
    BASE_WS_URL: 'wss://api.testpuush.fun',
    CHAT_BASE_HTTP_URL: 'http://localhost:3002',
    CHAT_BASE_WS_URL: 'ws://localhost:3002',
    PUUSHDOTFUN_CONTRACT_ADDRESS: '0xF1F49C1c7EB2C9680739A5cE33dA6efD56E7431b',
    PUUSHDOTFUN_V101_CONTRACT_ADDRESS: '0xd61333A445fd9bF780f021141Fdf6167F3fd8636',
    PUUSHDOTFUN_V102_CONTRACT_ADDRESS: '0x24Ec328d98F0D856fA0B63500408D4C4D73bEb39',
    PUUSHDOTFUN_V103_CONTRACT_ADDRESS: '0x9d794235827C2b9eBdf89FC86a15cE76432d4bB2',
    PUUSHDOTFUN_V103_CONTRACT_ADDRESS_CRONOSZKEVM: '0x25880aaF1847B4C2Fb301204556BdfB356D191c3',
    SPOTLIGHT_CONTRACT_ADDRESS: '0xE77306AC3a5eB6e6dAcaE7AFD20978AfedB2C429',
    PUUSHDOTWIN_CONTRACT_ADDRESS: '0x6Fc6a773c72898E79BE64cEddc923ec225C575f5',
    PUUSHDOTLIQUIDITYV101_CONTRACT_ADDRESS: '0x1C125bf1D1635c1496EEF6Da157b723b73CB861C',
    PUUSHDOTLIQUIDITYV103_CONTRACT_ADDRESS: '0xB5e0803bBF48b8d248571b1D6BbF7CdaEb09A445',
    PUUSHDOTLIQUIDITYV103_CONTRACT_ADDRESS_CRONOSZKEVM: '0x14a6637d5990B01E746DA58Cb0d755ED6694715c',
    PUUSHDOTSPOTLIGHT_CRONOSZKEVM: '0xf5fa498154ca3c854f5473f3ca94e5769cdfeac7',
    prefix: 'dev',
};

const stageConfig: Config = {
    BASE_API_URL: 'https://api.testpuush.fun',
    BASE_WS_URL: 'wss://api.testpuush.fun',
    CHAT_BASE_HTTP_URL: 'https://chat.puush.fun',
    CHAT_BASE_WS_URL: 'wss://chat.puush.fun',
    PUUSHDOTFUN_CONTRACT_ADDRESS: '0xF1F49C1c7EB2C9680739A5cE33dA6efD56E7431b',
    PUUSHDOTFUN_V101_CONTRACT_ADDRESS: '0xd61333A445fd9bF780f021141Fdf6167F3fd8636',
    PUUSHDOTFUN_V102_CONTRACT_ADDRESS: '0x24Ec328d98F0D856fA0B63500408D4C4D73bEb39',
    PUUSHDOTFUN_V103_CONTRACT_ADDRESS: '0x9d794235827C2b9eBdf89FC86a15cE76432d4bB2',
    PUUSHDOTFUN_V103_CONTRACT_ADDRESS_CRONOSZKEVM: '0x25880aaF1847B4C2Fb301204556BdfB356D191c3',
    SPOTLIGHT_CONTRACT_ADDRESS: '0xE77306AC3a5eB6e6dAcaE7AFD20978AfedB2C429',
    PUUSHDOTWIN_CONTRACT_ADDRESS: '0x6Fc6a773c72898E79BE64cEddc923ec225C575f5',
    PUUSHDOTLIQUIDITYV101_CONTRACT_ADDRESS: '0x1C125bf1D1635c1496EEF6Da157b723b73CB861C',
    PUUSHDOTLIQUIDITYV103_CONTRACT_ADDRESS: '0xB5e0803bBF48b8d248571b1D6BbF7CdaEb09A445',
    PUUSHDOTLIQUIDITYV103_CONTRACT_ADDRESS_CRONOSZKEVM: '0x14a6637d5990B01E746DA58Cb0d755ED6694715c',
    PUUSHDOTSPOTLIGHT_CRONOSZKEVM: '0xf5fa498154ca3c854f5473f3ca94e5769cdfeac7',
    prefix: 'stage',

};

const prodConfig: Config = {
    BASE_API_URL: 'https://api.puush.fun',
    BASE_WS_URL: 'wss://api.puush.fun',
    CHAT_BASE_HTTP_URL: 'https://chat.puush.fun',
    CHAT_BASE_WS_URL: 'wss://chat.puush.fun',
    PUUSHDOTFUN_CONTRACT_ADDRESS: '0x339258a2161a7320160A1a503A30630D1EcaCb75',
    PUUSHDOTFUN_V101_CONTRACT_ADDRESS: '0x0A659723b49a687d68646d40D122E964B7fa5ec5',
    PUUSHDOTFUN_V102_CONTRACT_ADDRESS: '0xFc1A9C6d6256403cE28f349ed4159621776eeEa1',
    PUUSHDOTFUN_V103_CONTRACT_ADDRESS: '0x947dEa68587b23a88a2195fac8846143f6cE264E',
    PUUSHDOTFUN_V103_CONTRACT_ADDRESS_CRONOSZKEVM: '0x4297dd1898b497fe4aeb74fb4dcfa021c0edac78',
    SPOTLIGHT_CONTRACT_ADDRESS: '0xE77306AC3a5eB6e6dAcaE7AFD20978AfedB2C429',
    PUUSHDOTWIN_CONTRACT_ADDRESS: '0x6Fc6a773c72898E79BE64cEddc923ec225C575f5',
    PUUSHDOTLIQUIDITYV101_CONTRACT_ADDRESS: '0x1C125bf1D1635c1496EEF6Da157b723b73CB861C',
    PUUSHDOTLIQUIDITYV103_CONTRACT_ADDRESS: '0xB5e0803bBF48b8d248571b1D6BbF7CdaEb09A445',
    PUUSHDOTLIQUIDITYV103_CONTRACT_ADDRESS_CRONOSZKEVM: '0x14a6637d5990B01E746DA58Cb0d755ED6694715c',
    PUUSHDOTSPOTLIGHT_CRONOSZKEVM: '0xf5fa498154ca3c854f5473f3ca94e5769cdfeac7',
    prefix: 'prod',
};

const config: ConfigMap = {
    development: devConfig,
    staging: stageConfig,
    production: prodConfig,
};

type Env = 'development' | 'staging' | 'production';

// Ensure that the value of REACT_APP_ENV is one of the accepted values.
const env: Env = process.env.REACT_APP_ENV as Env;

// Ensure we have a valid config for the given environment.
if (!config[env]) {
    throw new Error(`Invalid or missing environment setting: REACT_APP_ENV=${env}`);
}

export default config[env];
