// src/components/Web3Provider.tsx
import React, { ReactNode } from 'react';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';

function getLibrary(provider: any): Web3Provider {
    return new Web3Provider(provider);
}

interface Props {
    children: ReactNode;
}

const Web3ProviderComponent: React.FC<Props> = ({ children }) => {
    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            {children}
        </Web3ReactProvider>
    );
};

export default Web3ProviderComponent;
