import { useGlobalInfo } from '../../hooks/useGlobalInfo';
import { toEth } from '../../utils/priceUtils';
import CustomCoin from '../CustomCoin/CustomCoin';
import './PuushBurnTracker.css'

const PuushBurnTracker = () => {

    const { globalInfo, loading, error } = useGlobalInfo();

    const numberToUsLocale = (num: string) => {
        return parseFloat(num).toLocaleString('en-US', { maximumFractionDigits: 2 });
    };


    return (
        <>
            {
                loading ? null : globalInfo ?
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        [<img className='PuushBurnTracker-icon' src={'https://puushdabutton-web-images.s3.amazonaws.com/puush_burn_icon.png'}></img>{loading ? null : globalInfo ? <span>{numberToUsLocale(toEth(globalInfo.PUUSHBurned))}</span> : null}]
                    </div> : null}
        </>
    );
};

export default PuushBurnTracker;