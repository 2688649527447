import { ethers } from 'ethers';
import puushdotApi from '../requests/puushdotfunApi';

// This function will be called when the user tries to enter the "Arcade" section of the app
const signMessage = async (provider: ethers.BrowserProvider, userAddress: string) => {
    try {
        // 1. Create a message for the user to sign
        const message = `Welcome to Puush dot Fun! Please sign this message to verify your ownership of the address: ${userAddress}`;

        // 2. Request signature
        const signer = await provider.getSigner();
        const signature = await signer.signMessage(message);

        // 3. Send these details to your backend
        const data = await puushdotApi.authenticateUser(userAddress, message, signature);

        return data;
    } catch (error) {
        console.error("Error signing the message:", error);
    }
};

export default signMessage;
