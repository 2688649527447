// FILE: src/components/CoinGridFiltering/CoinGridFiltering.tsx
import React, { useEffect, useState } from 'react';
import './CoinGridFiltering.css';
import useWallet from '../../hooks/useWallet';

interface CoinGridFilteringProps {
    onSortChange: (sortBy: string) => void;
    onChainChange: (chainOption: string) => void;
    onShowGraduatedToggle: (showGraduated: boolean) => void;
    onShowNSFWToggle: (showNSFW: boolean) => void;
    onSearchChange: (searchQuery: string) => void;
    onShowOwnedOnlyToggle: (showOwnedOnly: boolean) => void;
    ownedOnlyActive: boolean;
}

const CoinGridFiltering: React.FC<CoinGridFilteringProps> = ({ onSortChange, onChainChange, onShowGraduatedToggle, onShowNSFWToggle, onSearchChange, onShowOwnedOnlyToggle, ownedOnlyActive }) => {

    const { chainId } = useWallet();

    const [sortBy, setSortBy] = useState<string>('lastUpdated');
    const [chainOption, setChainOption] = useState<string>('all');
    const [showGraduated, setShowGraduated] = useState<boolean>(false);
    const [showNSFW, setShowNSFW] = useState<boolean>(true);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [showOwnedOnly, setShowOwnedOnly] = useState<boolean>(false);

    // Set the default chain option to the current chain
    useEffect(() => {
        if (!chainId) return;
        switch (chainId) {
            case 25:
                handleChainChange({ target: { value: '25' } } as React.ChangeEvent<HTMLSelectElement>);
                break;
            case 388:
                handleChainChange({ target: { value: '388' } } as React.ChangeEvent<HTMLSelectElement>);
                break;
            default:
                setChainOption('all');
        };
    }, [chainId]);

    const handleChainChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        setChainOption(value);
        onChainChange(value);
    };

    const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        setSortBy(value);
        onSortChange(value);
    };

    const handleToggleChange = () => {
        setShowGraduated(!showGraduated);
        onShowGraduatedToggle(!showGraduated);
    };

    const handleNSFWtoggleChange = () => {
        setShowNSFW(!showNSFW);
        onShowNSFWToggle(!showNSFW);
    }

    const handleOwnedOnlyToggleChange = () => {
        setShowOwnedOnly(!showOwnedOnly);
        onShowOwnedOnlyToggle(!showOwnedOnly);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchQuery(value);
        onSearchChange(value);
    };

    return (
        <div className="CoinGridFiltering-container">
            <div className="CoinGridFiltering-section">
                <select className="CoinGridFiltering-select" value={chainOption} onChange={handleChainChange}>
                    <option value="all">CHAIN: All Chains</option>
                    <option value="25">CHAIN: Cronos</option>
                    <option value="388">CHAIN: Cronos zkEvm</option>
                </select>
            </div>
            <div className="CoinGridFiltering-section">
                <select className="CoinGridFiltering-select" value={sortBy} onChange={handleSortChange}>
                    <option value="lastUpdated">SORT: Last Updated</option>
                    <option value="dateCreated">SORT: Date Created</option>
                    <option value="fundsCoinBalance">SORT: % Sold</option>
                </select>
            </div>
            <div className='CoinGridFiltering-toggle-container'>
                <div className="toggle-container">
                    <label className="toggle-switch">
                        <input type="checkbox" checked={showGraduated} onChange={handleToggleChange} />
                        <span className="toggle-slider"></span>
                    </label>
                    <label className="toggle-label">Graduated</label>
                </div>
                <div className="toggle-container">
                    <label className="toggle-switch">
                        <input type="checkbox" checked={showNSFW} onChange={handleNSFWtoggleChange} />
                        <span className="toggle-slider"></span>
                    </label>
                    <label className="toggle-label">NSFW</label>
                </div>
                <div className="toggle-container">
                    <label className="toggle-switch">
                        <input type="checkbox" checked={showOwnedOnly} onChange={handleOwnedOnlyToggleChange} disabled={!ownedOnlyActive} />
                        <span className={`toggle-slider ${ownedOnlyActive ? '' : 'disabled'}`}></span>
                    </label>
                    <label className="toggle-label">
                        <div className='full-row gapped'>
                            Owned{!ownedOnlyActive && (<span className='text-orange'>(Must connect wallet)</span>)}
                        </div>
                    </label>
                </div>
            </div>
            <div className="CoinGridFiltering-section">
                <input
                    type="text"
                    placeholder="Search by name or ticker"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className='CoinGridFiltering-input'
                />
            </div>
        </div>
    );
};

export default CoinGridFiltering;
