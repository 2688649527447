import { ethers } from 'ethers';
import { Decimal } from 'decimal.js';

const SUPPLY_FOR_SALE = ethers.parseUnits('800000000', 18).toString(); // Total supply for sale in wei
const SUPPLY_FOR_SALE_V102 = ethers.parseUnits('650000000', 18).toString(); // Total supply for sale in wei
const START_PRICE = ethers.parseUnits('22727272727272', 0).toString(); // Start price in wei
const END_PRICE = ethers.parseUnits('227272727272727', 0).toString(); // End price in wei
const PRECISION = ethers.parseUnits('100000', 0).toString(); // Precision for rounding
const INITIAL_COIN_RESERVE = ethers.parseUnits('1000000000', 18).toString(); // Initial coin reserve in wei

export const getBuyCost = (amountToBuyWei: string, currentSupplySoldWei: string): string => {
    // Constants (should match those in the smart contract)
    // Constants (should match those in the smart contract)
    const startPrice = BigInt('22727272727272').toString(); // already in wei
    const endPrice = BigInt('227272727272727').toString(); // already in wei
    const totalSupply = ethers.parseEther('800000000').toString();

    const costInWei = calculateCost(startPrice, endPrice, totalSupply, currentSupplySoldWei, amountToBuyWei);
    const feeInWei = divBigInts(costInWei, BigInt(100));
    const totalCostInWei = addBigInts(costInWei, feeInWei);
    return totalCostInWei;
};

export const getBuyCostV102 = (amountToBuyWei: string, currentSupplySoldWei: string): string => {
    // Constants (should match those in the smart contract)
    // Constants (should match those in the smart contract)
    const startPrice = BigInt('22727272727272').toString(); // already in wei
    const endPrice = BigInt('227272727272727').toString(); // already in wei
    const totalSupply = ethers.parseEther('650000000').toString();

    const costInWei = calculateCost(startPrice, endPrice, totalSupply, currentSupplySoldWei, amountToBuyWei);
    const feeInWei = divBigInts(costInWei, BigInt(100));
    const totalCostInWei = addBigInts(costInWei, feeInWei);
    return totalCostInWei;
};

function interpolatePrice(startPrice: string, endPrice: string, totalSupply: string, supply: bigint) {
    const diffPrice = BigInt(endPrice) - BigInt(startPrice);
    const scaledSupply = (BigInt(supply) * diffPrice) / BigInt(totalSupply);
    return (BigInt(startPrice) + scaledSupply).toString();
}

function calculateCost(startPrice: string, endPrice: string, totalSupply: string, currentSupplySold: string, amountToBuy: string) {
    const start = BigInt(currentSupplySold);
    const end = BigInt(currentSupplySold) + BigInt(amountToBuy);

    const startPricePerToken = interpolatePrice(startPrice, endPrice, totalSupply, start);
    const endPricePerToken = interpolatePrice(startPrice, endPrice, totalSupply, end);

    const avgPricePerToken = (BigInt(startPricePerToken) + BigInt(endPricePerToken)) / BigInt('2');
    const cost = (avgPricePerToken * BigInt(amountToBuy)) / BigInt('1000000000000000000'); // Adjust for wei

    return cost.toString();
}

// Function to round down to the nearest specified precision
function roundDownToNearest(value: bigint, precision: bigint): bigint {
    return (value / precision) * precision;
}

// Function to calculate the amount of tokens that can be bought with the given ETH
export function getAmountToBuy(currentSupplySold: string, ethAmountInWei: string): string {
    let low = BigInt(0);
    let high = BigInt(SUPPLY_FOR_SALE) - BigInt(currentSupplySold);
    let mid: bigint;
    let cost: bigint;

    while (low < high) {
        mid = (low + high + BigInt(1)) / BigInt(2);
        cost = BigInt(calculateCost(START_PRICE, END_PRICE, SUPPLY_FOR_SALE, currentSupplySold, mid.toString()));

        if (cost <= BigInt(ethAmountInWei)) {
            low = mid;
        } else {
            high = mid - BigInt(1);
        }
    }

    return roundDownToNearest(low, BigInt(PRECISION)).toString();
}

// Function to calculate the amount of tokens that can be bought with the given ETH
export function getAmountToBuy2(currentSupplySold: string, ethAmountInWei: string): string {
    let low = BigInt(0);
    let high = BigInt(SUPPLY_FOR_SALE_V102) - BigInt(currentSupplySold);
    let mid: bigint;
    let cost: bigint;

    while (low < high) {
        mid = (low + high + BigInt(1)) / BigInt(2);
        cost = BigInt(calculateCost(START_PRICE, END_PRICE, SUPPLY_FOR_SALE_V102, currentSupplySold, mid.toString()));

        if (cost <= BigInt(ethAmountInWei)) {
            low = mid;
        } else {
            high = mid - BigInt(1);
        }
    }

    return roundDownToNearest(low, BigInt(PRECISION)).toString();
}

// Multiplies and divides two big integers
function mulDiv(a: bigint, b: bigint, c: bigint): bigint {
    return (a * b) / c;
}

// Function to calculate the return when selling tokens
export function getReturn(
    currentSupplySold: string,
    amountToSell: string,
    ethReserve: string,
    coinReserve: string
): string {
    const start = BigInt(currentSupplySold);
    const end = start - BigInt(amountToSell);

    const startPricePerToken = BigInt(interpolatePrice(START_PRICE, END_PRICE, SUPPLY_FOR_SALE, start));
    const endPricePerToken = BigInt(interpolatePrice(START_PRICE, END_PRICE, SUPPLY_FOR_SALE, end));

    const avgPricePerToken = mulDiv(startPricePerToken + endPricePerToken, BigInt('1000000000000000000'), BigInt('2000000000000000000'));

    const ethGrossReturn = mulDiv(avgPricePerToken, BigInt(amountToSell), BigInt('1000000000000000000')); // Adjust for wei

    let fee = mulDiv(ethGrossReturn, BigInt(1), BigInt(100)); // 1% fee
    let ethReturn = ethGrossReturn - fee; // Amount user gets after fee
    let totalReturnWithFee = ethGrossReturn; // Total amount including fee

    // Check if selling the remaining coins
    if (BigInt(amountToSell) + BigInt(coinReserve) >= BigInt(INITIAL_COIN_RESERVE)) {
        totalReturnWithFee = BigInt(ethReserve);
        fee = mulDiv(totalReturnWithFee, BigInt(1), BigInt(100)); // 1% fee
        ethReturn = totalReturnWithFee - fee;
    }

    return ethReturn.toString();
}

// Function to calculate the return when selling tokens
export function getReturnV102(
    currentSupplySold: string,
    amountToSell: string,
    ethReserve: string,
    coinReserve: string
): string {
    const start = BigInt(currentSupplySold);
    const end = start - BigInt(amountToSell);

    const startPricePerToken = BigInt(interpolatePrice(START_PRICE, END_PRICE, SUPPLY_FOR_SALE_V102, start));
    const endPricePerToken = BigInt(interpolatePrice(START_PRICE, END_PRICE, SUPPLY_FOR_SALE_V102, end));

    const avgPricePerToken = mulDiv(startPricePerToken + endPricePerToken, BigInt('1000000000000000000'), BigInt('2000000000000000000'));

    const ethGrossReturn = mulDiv(avgPricePerToken, BigInt(amountToSell), BigInt('1000000000000000000')); // Adjust for wei

    let fee = mulDiv(ethGrossReturn, BigInt(1), BigInt(100)); // 1% fee
    let ethReturn = ethGrossReturn - fee; // Amount user gets after fee
    let totalReturnWithFee = ethGrossReturn; // Total amount including fee

    // Check if selling the remaining coins
    if (BigInt(amountToSell) + BigInt(coinReserve) >= BigInt(INITIAL_COIN_RESERVE)) {
        totalReturnWithFee = BigInt(ethReserve);
        fee = mulDiv(totalReturnWithFee, BigInt(1), BigInt(100)); // 1% fee
        ethReturn = totalReturnWithFee - fee;
    }

    return ethReturn.toString();
}

// TODO: Fix for ZKSync Cronos

export const getCoinCreationLogs = (tx: any, withBuy: boolean): { coinAddress: string, fundsAddress: string, coinCreator: string } => {
    const logs = tx.logs;
    let index = logs.length - (withBuy ? 3 : 1);
    if (withBuy) {

    }
    const logArray = (logs[index] as any).args;
    const coinAddress = logArray[0];
    const fundsAddress = logArray[1];
    const coinCreator = logArray[2];
    // const coinToFunds = logArray[3];
    // const metaURI = logArray[4];

    return {
        coinAddress,
        fundsAddress,
        coinCreator,
    };
};

// Function to convert wei to ether and format with a specific number of decimal places
export const toEth = (wei: string, decimals: number = 0): string => {
    return new Decimal(ethers.formatEther(wei)).toFixed(decimals);
};

export const toWei = (eth: string | number | bigint): string => {
    return ethers.parseEther(eth.toString()).toString();
};

// const subBigInts = (a: any, b: any): string => {
//     return (BigInt(a.toString()) - BigInt(b.toString())).toString();
// };

const addBigInts = (a: any, b: any): string => {
    return (BigInt(a.toString()) + BigInt(b.toString())).toString();
}

// const mulBigInts = (a: any, b: any): string => {
//     return (BigInt(a.toString()) * BigInt(b.toString())).toString();
// }

const divBigInts = (a: any, b: any): string => {
    return (BigInt(a.toString()) / BigInt(b.toString())).toString();
}

// Define a utility function to format numbers with up to 18 decimals
export const formatDecimal = (value: Decimal) => {
    return value.toFixed(18).replace(/\.?0+$/, '');
};

export const handleInputChangeWithMinMax = (
    event: React.ChangeEvent<HTMLInputElement>,
    setter: React.Dispatch<React.SetStateAction<bigint>>,
    displaySetter: React.Dispatch<React.SetStateAction<string>>,
    minAmount: Decimal,
    maxAmount: Decimal,
    decimals: number,
) => {
    let newValue = event.target.value;
    // Regular expression to validate the input is a positive numeric value and allows up to 18 decimal places.

    // If the input is empty, reset the state but don't log an error
    if (newValue === '') {
        displaySetter('0');
        setter(BigInt(0));
        return;
    }

    // Remove leading zeros
    newValue = newValue.replace(/^0+(?=\d)/, '');

    // Limit input decimal places
    const decimalIndex = newValue.indexOf('.');
    const truncatedValue = decimalIndex >= 0 ? newValue.slice(0, decimalIndex + decimals + 1) : newValue;

    const isValidNumber = new RegExp(`^\\d*(\\.\\d{0,${decimals}})?$`).test(truncatedValue);

    if (isValidNumber) {
        const weiValue = BigInt(toWei(truncatedValue));
        const ethValue = new Decimal(truncatedValue);

        if (ethValue.gte(minAmount) && ethValue.lte(maxAmount)) { // If the input is within the valid range
            displaySetter(truncatedValue);
            setter(weiValue);
        } else if (ethValue.gte(minAmount) && ethValue.gte(maxAmount)) {
            displaySetter(maxAmount.toFixed(decimals));
            setter(weiValue);
        } else {
            console.error(`Invalid input: Please enter a valid number between ${minAmount} and ${maxAmount} (up to ${decimals} decimal places).`);
            displaySetter('0');
            setter(BigInt(0)); // Reset the state to zero if the input is invalid or out of range.
        }
    } else {
        console.error(`Invalid input: Please enter a valid number between ${minAmount} and ${maxAmount} (up to ${decimals} decimal places).`);
        displaySetter('0');
        setter(BigInt(0)); // Reset the state to zero if the input is invalid.
    }
};

// const handleInputChange = (
//     event: React.ChangeEvent<HTMLInputElement>,
//     displaySetter: React.Dispatch<React.SetStateAction<string>>,
//     weiSetter: React.Dispatch<React.SetStateAction<string>>) => {
//     const newValue = event.target.value;
//     // Regular expression to validate the input is a positive numeric value and allows up to 18 decimal places.
//     const isValidNumber = /^\d*(\.\d{0,18})?$/.test(newValue);
//     if (isValidNumber) {
//         // It's a valid number; we can set the state.
//         displaySetter(newValue);


//         //convert to wei
//         const weiValue = Web3.utils.toWei(newValue, 'ether');
//         weiSetter(weiValue);

//     } else {
//         // It's not a valid number. Here you might want to provide feedback to the user.
//         console.error("Invalid input: Please enter a valid number (up to 18 decimal places).");

//         // Reset the state to '0' if the input is invalid.
//         weiSetter('0');
//         displaySetter('0');
//     }
// };

// export const handleInputChangeWithMinMax = (
//     event: React.ChangeEvent<HTMLInputElement>,
//     setter: React.Dispatch<React.SetStateAction<number | string>>,
//     minAmount: number,
//     maxAmount: number
// ) => {
//     const newValue = event.target.value;
//     // Regular expression to validate the input is a positive numeric value and allows up to 18 decimal places.
//     const isValidNumber = /^\d*(\.\d{0,18})?$/.test(newValue);
//     const numericValue = parseFloat(newValue);

//     if (isValidNumber && numericValue >= minAmount && numericValue <= maxAmount) {
//         setter(numericValue);
//     } else if (isValidNumber && numericValue >= minAmount && numericValue >= maxAmount) {
//         setter(maxAmount);
//     } else {
//         console.error(`Invalid input: Please enter a valid number between ${minAmount} and ${maxAmount} (up to 18 decimal places).`);
//         setter(''); // Reset the state to an empty string if the input is invalid or out of range.
//     }
// };