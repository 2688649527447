// src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Web3ProviderComponent from './web3/Web3Provider';
import { WebSocketProvider } from './providers/WebSocketProvider';
import { CoinListProvider } from './providers/CoinListProvider';
import { GlobalInfoProvider } from './providers/GlobalInfoProvider';
import { ChatWebSocketProvider } from './providers/ChatWebsocketProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Web3ProviderComponent>
      <WebSocketProvider>
        <ChatWebSocketProvider>
          <CoinListProvider>
            <GlobalInfoProvider>
              <App />
            </GlobalInfoProvider>
          </CoinListProvider>
        </ChatWebSocketProvider>
      </WebSocketProvider>
    </Web3ProviderComponent>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
