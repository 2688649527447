import { ethers } from 'ethers';
import PuushdotFunV103 from './contracts/PuushdotFunV103.json';
import { getBuyCostV102, toWei } from '../utils/priceUtils';

class PuushdotV103Instance {
    private contract: any;
    public contractAddress: string;

    constructor(contract: any, contractAddress: string) {
        this.contract = contract;
        this.contractAddress = contractAddress;
    }

    public static async createInstance(provider: ethers.BrowserProvider, contractAddress: string): Promise<PuushdotV103Instance> {
        const signer = await provider.getSigner();
        const contract = new ethers.Contract(contractAddress, PuushdotFunV103.abi, signer);
        return new PuushdotV103Instance(contract, contractAddress);
    }

    public getContractAddress(): string {
        return this.contractAddress;
    };

    public async createCoin(
        name: string,
        symbol: string,
        metaURI: string,
        minCoinsToBuy: number, // In wei or ETH as a string
    ): Promise<ethers.ContractTransaction> {
        try {
            const baseCostWei = ethers.parseEther('50').toString();
            const costToBuyCoinsWei = getBuyCostV102(toWei(minCoinsToBuy), '0');
            const minCoinsToBuyWei = ethers.parseEther(minCoinsToBuy.toString()).toString();
            const totalEthCost = BigInt(baseCostWei) + BigInt(costToBuyCoinsWei);

            const tx = await this.contract.createCoin(
                name,
                symbol,
                metaURI,
                minCoinsToBuyWei,
                {
                    value: totalEthCost,
                }
            );
            return tx.wait();
        } catch (error) {
            console.error('Error creating coin:', error);
            throw error;
        }
    }

    public async buyCoins(
        coinAddress: string,
        amountToBuy: string,
        minAmountToBuy: string,
        currentSupplySold: string
    ): Promise<ethers.ContractTransaction> {
        try {
            // Get cost to buy coins based off currentSupplySold and amountToBuy
            let totalCost = getBuyCostV102(amountToBuy, currentSupplySold);

            if (totalCost === '0') {
                totalCost = ethers.parseEther("1").toString();
            }

            // Estimate the gas required for the transaction
            const estimatedGas = await this.contract.buyCoins.estimateGas(
                coinAddress,
                minAmountToBuy,
                {
                    value: totalCost,
                }
            );

            // Double the gas limit
            const gasLimit = BigInt(estimatedGas) * BigInt(2)

            const tx = await this.contract.buyCoins(
                coinAddress,
                minAmountToBuy,
                {
                    value: totalCost,
                    gasLimit: gasLimit.toString(),
                }
            );

            return tx.wait();
        } catch (error) {
            console.error('Error buying coins:', error);
            throw error;
        }
    }

    public async sellCoins(
        coinAddress: string,
        amountToSell: string,
        minEthReturn: string
    ): Promise<ethers.ContractTransaction> {
        try {

            // Estimate the gas required for the transaction
            const estimatedGas = await this.contract.sellCoins.estimateGas(
                coinAddress,
                amountToSell,
                minEthReturn
            );


            // Double the gas limit
            const gasLimit = BigInt(estimatedGas) * BigInt(2)

            // Send the transaction with the tripled gas limit
            const tx = await this.contract.sellCoins(
                coinAddress,
                amountToSell,
                minEthReturn,
                {
                    gasLimit: gasLimit.toString(), // Set the tripled gas limit
                }
            );
            return tx.wait();
        } catch (error) {
            console.error('Error selling coins:', error);
            throw error;
        }
    }

    public async viewCoinCreateTimestamp(coinAddress: string): Promise<string> {
        try {
            return await this.contract.coinCreateTimestamp(coinAddress);
        } catch (error) {
            console.error('Error getting coin create timestamp:', error);
            throw error;
        }
    };
}

export default PuushdotV103Instance;
