import './Header.css'
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import useWallet from '../../hooks/useWallet';
import { openURLInNew } from '../../utils/generalUtils';
import Connect from '../Connect/Connect';
import Modal from '../Modal/Modal';
import Spotlight from '../Spotlight/Spotlight';
import PuushBurnTracker from '../PuushBurnTracker/PuushBurnTracker';
import CustomCoin from '../CustomCoin/CustomCoin';
import PuushToWin from '../PuushToWin/PuushToWin';

function Header() {
    const {
        isConnected,
        isAuthenticated,
        account,
        connectWithAuth,
        disconnectWallet,
        loading,
        chainId,
    } = useWallet();

    const [modalOpen, setModalOpen] = useState(false);
    const [burnModalOpen, setBurnModalOpen] = useState(false);

    const navigate = useNavigate();

    const toHome = () => {
        navigate('/');
    };

    const toStartnewCoin = () => {
        navigate('/start-new-coin');
    };

    const toTradingRewards = () => {
        navigate('/trading-rewards');
    }


    return (
        <div className='Header'>
            <div className='Header-top'>
                <img className='Header-logo'
                    src={'https://puushdabutton-web-images.s3.amazonaws.com/puushfun128.png'}
                    onClick={toHome}
                    alt='puush dot fun logo'
                />
                <Connect
                    connectWithAuth={connectWithAuth}
                    disconnectWallet={disconnectWallet}
                    account={account}
                    isConnected={isConnected}
                    isAuthenticated={isAuthenticated}
                    loading={loading}
                    chainId={chainId}
                />
            </div>
            <div className='Header-mid'>
                <div className='text-clicky' onClick={(e) => openURLInNew(e, 'https://twitter.com/PUUSHDABUTTON')}>
                    {'[twitter↗️]'}
                </div>
                <div className='text-clicky' onClick={(e) => openURLInNew(e, 'https://discord.gg/boomer-squad-nft-939820143486320650')}>
                    {'[discord↗️]'}
                </div>
                <div className='text-clicky' onClick={(e) => openURLInNew(e, 'https://t.me/puushdabuttonportal')}>
                    {'[telegram↗️]'}
                </div>
                <div className='text-clicky' onClick={(e) => openURLInNew(e, 'https://app.ebisusbay.com/dex/swap?outputCurrency=0x288898a6057d2D4989c533E96Cb3bc30843c91D7')}>
                    {'[buy $puush↗️]'}
                </div>
            </div>
            <div className='Header-mid-two'>
                <div className='text-clicky' onClick={() => setModalOpen(true)}>
                    {'[👉how to puush]'}
                </div>
                <div className='text-clicky' onClick={() => setBurnModalOpen(true)}>
                    <PuushBurnTracker />
                </div>
                <div className='text-clicky' onClick={toTradingRewards}>
                    {'[🏆trading rewards]'}
                </div>
            </div>
            <div className="full-row-column">
                {
                    window.location.pathname !== '/' ?
                        <div style={{ marginTop: '1rem' }}>
                            <div className='full-row gapped'>
                                <Spotlight />
                                {/* <PuushToWin /> */}
                            </div>
                            <h3
                                className="Header-main-menu-text text-clicky"
                                onClick={toHome}
                            >{'[Main Menu]'}
                            </h3>
                        </div>
                        : <h2
                            className="text-clicky"
                            onClick={toStartnewCoin}
                        >{'[Start New Coin]'}</h2>
                }
            </div>
            <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
                <h2>How to Puush</h2>
                <p className='text-white'>Puush is a fair launch platform with no pre-sale or free team allocation. It is designed to prevent rugs by making sure that all created coins are safe.</p>
                <p><span className='text-white'>1: </span>Pick coin</p>
                <p><span className='text-white'>2: </span>Buy & Sell coin on the bonding curve</p>
                <p><span className='text-white'>3: </span>When enough coins are bought on the curve, the coin will graduate to a DEX</p>
                <p><span className='text-white'>4: </span>Once a coin graduates, it can no longer be traded on Puush.fun</p>
                <p><span className='text-white'>5: </span>Check the <a href={'https://boomer-squad.gitbook.io/boomer-squad-nft/ecosystem-services/puush.fun#getting-started'}>docs</a> for more details</p>
                <p className='text-orange'>This is a DeFi protocol open to the public to use at will. Participate at your own risk.</p>
            </Modal>
            <Modal isOpen={burnModalOpen} onClose={() => setBurnModalOpen(false)}>
                <div className='full-row-left'>
                    <CustomCoin url={'https://cdn-prod.ebisusbay.com/files/dex/images/tokens/puush.webp'} size={30} /><h2> $PUUSH Burning</h2>
                </div>
                <div className='full-row-left'>
                    🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥
                </div>
                <p className='text-white'>
                    $PUUSH is the BoomerSquad meme token and closely tied to our ecosystem developments. It is one of the first actively deflationary meme tokens with the backing of the puush.fun platform (and other upcoming developments). Fees raised from the puush.fun platform are used for $PUUSH buy back and burns along with platform and product development.
                </p>
                <div className='full-row'>
                    <img className='Header-burn-gif' src={'https://puushdabutton-web-images.s3.amazonaws.com/puushburn.gif'} alt='puush burn icon' />
                </div>
            </Modal>
        </div>
    );
}

export default Header;