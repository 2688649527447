import React, { useState, ChangeEvent } from 'react';
import useWallet from '../../hooks/useWallet';
import { CoinGeneralInfo, useCoinList } from '../../providers/CoinListProvider';
import { ethers } from 'ethers';
import './BuySpotlight.css';
import { getCroImage } from '../../utils/generalUtils';

const BuySpotlight: React.FC = () => {
    const [inputValue, setInputValue] = useState<string>('');
    const [validationMessage, setValidationMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const { balance, spotlightInstance, refreshBalances, chainId } = useWallet();
    const { coinList } = useCoinList();

    const validateAddress = (address: string): boolean => {
        // Check if the address is a valid Ethereum address
        if (!ethers.isAddress(address)) {
            setValidationMessage('Invalid address.');
            return false;
        }

        // Check if the address exists in the coinList
        const coinExists = coinList.some((coin: CoinGeneralInfo) => coin.coinAddress.toLowerCase() === address.toLowerCase());
        if (!coinExists) {
            setValidationMessage('Address not found.');
            return false;
        }

        setValidationMessage('Valid address!');
        return true;
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        setInputValue(value);

        if (value) {
            validateAddress(value);
        } else {
            setValidationMessage('');
        }
    };

    const handleBuySpotlight = async () => {
        if (loading) {
            return;
        }
        setLoading(true);
        if (!validateAddress(inputValue)) {
            return;
        }

        if (!spotlightInstance) {
            console.log('Spotlight instance not found');
            return;
        }

        // Buy spotlight
        try {
            await spotlightInstance.buySpotlight(inputValue);
            setValidationMessage('Spotlight bought successfully!');
            setLoading(false);
        } catch (error) {
            console.error('Error buying spotlight:', error);
            setValidationMessage('Error buying spotlight.');
            setLoading(false);
            return
        };
    };

    return (
        <div className='full-row-column'>
            <div className='BuySpotlight-howto'>
                <h2>How to Buy Spotlight</h2>
                <div className='full-row middle left gapped'><span className='text-white'>1: </span>Enter valid <span className='text-purple'>coin address</span></div>
                <div className='full-row middle left gapped'><span className='text-white'>2: </span>Costs <span className='text-blue'>100</span>{getCroImage(chainId)} to buy</div>
                <div className='full-row middle left gapped'><span className='text-white'>3: </span>Spotlight <span className='text-danger'>locked</span> for 15 minutes after buy</div>
                <div className='full-row middle left gapped'><span className='text-white'>4: </span><span className='text-green'>Keep Spotlight</span> until another user buys</div>
                <div className='full-row middle left gapped'><span className='text-white'>5: </span><span className='text-orange'>(Optional)</span> Buy Spotlight again to keep for 15 more minutes</div>
            </div>
            {validationMessage && <div className={(validationMessage.includes('Valid') || validationMessage.includes('successfully')) ? 'text-green' : 'text-red'}>{validationMessage}</div>}
            <input className='BuySpotlight-input' type="text" value={inputValue} onChange={handleInputChange} placeholder='Enter Coin Address' />
            <button
                className={`BuySpotlight-button buy ${!validationMessage.includes('Valid') && 'disabled'}`}
                onClick={() => handleBuySpotlight()}
                disabled={!validationMessage.includes('Valid')}
            >
                Buy
            </button>
        </div>
    );
};

export default BuySpotlight;
