import { ethers } from 'ethers';
import PuushdotWin from './contracts/PuushdotWin.json';
import config from '../config';

const contractAddress = config.PUUSHDOTWIN_CONTRACT_ADDRESS;

type UserGameState = {
    gameId: string;
    jackpot: string;
    lastJackpot: string;
    lastDepositTime: string;
    lastDepositId: string;
    timeLeft: string;
    accWinnings: string;
    lastDepositor: string;
    lastJackpotWinner: string;
    paused: boolean;
};

class PuushdotWinInstance {
    private contract: any;

    constructor(contract: any) {
        this.contract = contract;
    }

    public static async createInstance(provider: ethers.BrowserProvider): Promise<PuushdotWinInstance> {
        const signer = await provider.getSigner();
        const contract = new ethers.Contract(contractAddress, PuushdotWin.abi, signer);
        return new PuushdotWinInstance(contract);
    }

    public async deposit(): Promise<ethers.ContractTransaction> {
        try {
            const tx = await this.contract.deposit(
                {
                    value: ethers.parseEther('100'),
                }
            );
            return tx.wait();
        } catch (error) {
            console.error('Error depositing:', error);
            throw error;
        }
    };

    public async claimWinnings(): Promise<ethers.ContractTransaction> {
        try {
            const tx = await this.contract.claimWinnings();
            return tx.wait();
        } catch (error) {
            console.error('Error withdrawing:', error);
            throw error;
        }
    };

    public async viewLastDepositTime(): Promise<bigint> {
        try {
            return await this.contract.lastDepositTime();
        } catch (error) {
            console.error('Error getting last deposit time:', error);
            throw error;
        }
    };

    public async viewTimeLeft(): Promise<bigint> {
        try {
            return await this.contract.viewTimeLeft();
        } catch (error) {
            console.error('Error getting time left:', error);
            throw error;
        }
    };

    public async viewAccWinnings(account: string): Promise<bigint> {
        try {
            return await this.contract.accWinnings(account);
        } catch (error) {
            console.error('Error getting account winnings:', error);
            throw error;
        }
    };

    public async viewUserGameState(): Promise<UserGameState> {
        try {
            const userGameState = await this.contract.viewUserGameState();
            console.log('userGameState:', userGameState);
            return {
                gameId: userGameState.gameId.toString(),
                jackpot: userGameState.jackpot.toString(),
                lastJackpot: userGameState.lastJackpot.toString(),
                lastDepositTime: userGameState.lastDepositTime.toString(),
                lastDepositId: userGameState.lastDepositId.toString(),
                timeLeft: userGameState.timeLeft.toString(),
                accWinnings: userGameState.accWinnings.toString(),
                lastDepositor: userGameState.lastDepositor,
                lastJackpotWinner: userGameState.lastJackpotWinner,
                paused: userGameState.paused,
            };
        } catch (error) {
            console.error('Error getting game state:', error);
            throw error;
        };
    }
}

export default PuushdotWinInstance;
