import puushdotApi from '../../requests/puushdotfunApi';
import './StartNewCoin.css';
import React, { useState } from 'react';
import { ethers } from 'ethers';
import useWallet from '../../hooks/useWallet';
import useLoadingWithDots from '../../hooks/useLoadingWithDots';
import { getBuyCostV102, getCoinCreationLogs, toWei } from '../../utils/priceUtils';
import { useNavigate } from 'react-router-dom';
import { getCroImage } from '../../utils/generalUtils';

export type CoinMetadataPayload = {
    coinName: string; // Name of coin
    coinTick: string; // Ticker of coin
    coinDescription: string; // Description of coin
    coinImageProperties: {
        filename: string;
        mimetype: 'image/jpeg' | 'image/png' | 'image/gif';
        size: number; // Size in bytes
    };
    coinImageFile: File; // For file input
    coinTwitter?: string; // Link to twitter
    coinTelegram?: string; // Link to telegram
    coinWebsite?: string; // Link to website
    amountToBuy: number;
};

const StartNewCoin = () => {

    const { account, chainId, getCoinCreationInstance } = useWallet();
    const [fileError, setFileError] = useState('');

    const [showOptional, setShowOptional] = useState(false);
    const [formData, setFormData] = useState<CoinMetadataPayload>({
        coinName: '',
        coinTick: '',
        coinDescription: '',
        coinImageProperties: {
            filename: '',
            mimetype: 'image/jpeg',
            size: 0,
        },
        coinImageFile: null as any, // For file input, null initially
        coinTwitter: '',
        coinTelegram: '',
        coinWebsite: '',
        amountToBuy: 0,
    });

    const [loading, setLoading] = useState(false);
    const [successText, setSuccessText] = useState('');
    const loadingText = useLoadingWithDots(loading);
    const navigate = useNavigate();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;
        if (files && files.length > 0) {
            const file = files[0];

            // Check file size
            if (file.size > 2 * 1024 * 1024) {
                setFileError('File size must be less than 2 MB');
                return;
            }

            // Check file type
            const validMimeTypes = ['image/jpeg', 'image/png'] as const;
            if (!validMimeTypes.includes(file.type as any)) {
                setFileError('File type must be PNG or JPG');
                return;
            }

            // Clear any previous error messages
            setFileError('');

            // Update form data state
            setFormData((prevState) => ({
                ...prevState,
                coinImageProperties: {
                    filename: file.name,
                    mimetype: file.type as 'image/jpeg' | 'image/png',
                    size: file.size,
                },
                coinImageFile: file,
            }));
        }
    };




    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setSuccessText('');
        const puushdotInstance = getCoinCreationInstance();

        if (!puushdotInstance) {
            console.error('puushdotV102Instance is not initialized');
            setLoading(false);
            return;
        }
        if (!account) {
            console.error('Account is not initialized');
            setLoading(false);
            return;
        }

        let metadataId: string | null = null;

        const payload = new FormData();
        payload.append('coinName', formData.coinName);
        payload.append('coinTick', formData.coinTick);
        payload.append('coinDescription', formData.coinDescription);
        payload.append('coinImageProperties', JSON.stringify(formData.coinImageProperties));
        payload.append('coinImageFile', formData.coinImageFile);
        if (formData.coinTwitter) payload.append('coinTwitter', formData.coinTwitter);
        if (formData.coinTelegram) payload.append('coinTelegram', formData.coinTelegram);
        if (formData.coinWebsite) payload.append('coinWebsite', formData.coinWebsite);

        try {
            const response = await puushdotApi.submitMetadata(payload);
            // console.log('Coin created successfully:', response);
            metadataId = response.metadataId;
        } catch (error) {
            console.error('Error creating coin:', error);
            setSuccessText('Error creating coin');
            setLoading(false);
            return;
        }

        // Now we need to add code to handle the web3 transaction to create the coin
        if (!metadataId) {
            console.error('Metadata ID is null');
            setSuccessText('Error creating coin');
            setLoading(false);
            return;
        }

        try {
            const tx = await puushdotInstance.createCoin(
                formData.coinName,
                formData.coinTick,
                metadataId,
                formData.amountToBuy, // Minimum coins to buy, set to 1 for testing
            );
            console.log('Transaction successful:', tx);

            if (chainId === 25) {
                const hasBuy = formData.amountToBuy > 0;
                const logs = getCoinCreationLogs(tx, hasBuy);
                // Route users to new coin page after 3 seconds to allow time to load
                setSuccessText('Success! Redirecting to new Coin...');
                setTimeout(() => {
                    navigate(`/coin/${logs.coinAddress}`); // Redirect to the new coin page
                }, 3000);
            } else if (chainId === 388) {
                setSuccessText('Success! Redirecting to home...');
                setTimeout(() => {
                    navigate(`/`); // Redirect to the new coin page
                }, 3000);
            };
        } catch (error) {
            setLoading(false);
            setSuccessText('Error creating coin');
            return console.error('Error creating coin:', error);
        } finally {
        }
    };

    const toggleOptionalFields = () => {
        if (loading) return
        setShowOptional(!showOptional);
    };

    return (
        <div className="full-row">
            <div className="StartNewCoin-container">
                <div className='StartNewCoin-title'>Enter coin info</div>
                <form className="StartNewCoin-form" onSubmit={handleSubmit}>
                    <div className="Form-section">
                        <label className='Form-label' htmlFor="coinName">Name</label>
                        <input
                            className={`Form-input ${loading ? 'disabled' : ''}`}
                            type="text"
                            id="coinName"
                            name="coinName"
                            value={formData.coinName}
                            onChange={handleChange}
                            required
                            disabled={loading}
                            maxLength={32}
                        />
                    </div>
                    <div className="Form-section">
                        <label className='Form-label' htmlFor="coinTick">Ticker</label>
                        <input
                            className={`Form-input ${loading ? 'disabled' : ''}`}
                            type="text"
                            id="coinTick"
                            name="coinTick"
                            value={formData.coinTick}
                            onChange={handleChange}
                            required
                            disabled={loading}
                            maxLength={10}
                        />
                    </div>
                    <div className="Form-section">
                        <label className='Form-label' htmlFor="coinDescription">Description</label>
                        <textarea
                            className={`Form-input ${loading ? 'disabled' : ''}`}
                            id="coinDescription"
                            name="coinDescription"
                            value={formData.coinDescription}
                            onChange={handleChange}
                            required
                            disabled={loading}
                            maxLength={500}
                        />
                    </div>
                    <div className="Form-section">
                        <label className="Form-label" htmlFor="coinImage">Image (up to 2 MB, png, jpg)</label>
                        <input
                            className={`Form-input ${loading ? 'disabled' : ''}`}
                            type="file"
                            id="coinImage"
                            name="coinImage"
                            onChange={handleFileChange}
                            required
                            disabled={loading}
                            accept=".png, .jpg, .jpeg" // Enforce file types
                        />
                        {fileError && <p className="Form-error">{fileError}</p>}
                    </div>
                    <div className="Form-section">
                        <label className='Form-label' htmlFor="amountToBuy">Amount to Buy ({((formData.amountToBuy / 1_000_000_000) * 100).toFixed(2)}% of total supply) = {Number(ethers.formatEther(getBuyCostV102(toWei(formData.amountToBuy), '0'))).toFixed(2)}{getCroImage(chainId)}</label>
                        <input
                            className={`Form-input ${loading ? 'disabled' : ''}`}
                            type="number"
                            id="amountToBuy"
                            name="amountToBuy"
                            value={formData.amountToBuy}
                            onChange={(e) => {
                                const value = Math.max(0, Math.min(400_000_000, Math.floor(Number(e.target.value))));
                                setFormData({
                                    ...formData,
                                    amountToBuy: value
                                });
                            }}
                            min="0"
                            max="400000000"
                            step="1"
                            required
                            disabled={loading}
                        />
                    </div>
                    <button className={`Form-button optional ${loading ? 'disabled' : ''}`} type="button" onClick={toggleOptionalFields}>
                        {showOptional ? 'Hide Optional Fields' : 'Show Optional Fields'}
                    </button>
                    {showOptional && (
                        <>
                            <div className="Form-section">
                                <label className='Form-label' htmlFor="coinTwitter">Twitter - (https://x.com/example)</label>
                                <input
                                    className={`Form-input ${loading ? 'disabled' : ''}`}
                                    type="text"
                                    id="coinTwitter"
                                    name="coinTwitter"
                                    value={formData.coinTwitter}
                                    onChange={handleChange}
                                    disabled={loading}
                                />
                            </div>
                            <div className="Form-section">
                                <label className='Form-label' htmlFor="coinTelegram">Telegram - (https://t.me/example)</label>
                                <input
                                    className={`Form-input ${loading ? 'disabled' : ''}`}
                                    type="text"
                                    id="coinTelegram"
                                    name="coinTelegram"
                                    value={formData.coinTelegram}
                                    onChange={handleChange}
                                    disabled={loading}
                                />
                            </div>
                            <div className="Form-section">
                                <label className='Form-label' htmlFor="coinWebsite">Website - (https://www.example.com)</label>
                                <input
                                    className={`Form-input ${loading ? 'disabled' : ''}`}
                                    type="text"
                                    id="coinWebsite"
                                    name="coinWebsite"
                                    value={formData.coinWebsite}
                                    onChange={handleChange}
                                    disabled={loading}
                                />
                            </div>
                        </>
                    )}
                    <div className="Form-section">
                        <div className='full-row-between'>
                            <button className={`Form-button ${loading ? 'disabled' : ''}`} disabled={loading} type="submit">Start Coin</button>
                            <div
                                className={`
                                ${successText.includes('Success!') ? 'Form-success' : loading ? 'Form-loading' : 'Form-error'} ${loading ? 'disabled' : ''}`
                                }
                            >
                                {successText.includes('Success!') ? successText : loading ? loadingText : successText}
                            </div>
                        </div>
                        <label className='Form-bigLabel' htmlFor="coinWebsite">{'Price = 50'}{getCroImage(chainId)}{' +'}{Number(ethers.formatEther(getBuyCostV102(toWei(formData.amountToBuy), '0'))).toFixed(2)}{getCroImage(chainId)}{'+gas'}</label>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default StartNewCoin;
