import { useNavigate } from "react-router-dom";
import { CoinGeneralInfo } from "../../providers/CoinListProvider";
import './BiggestPuusher.css';
import { format } from 'date-fns';
import { getCroImage, getPercentageSold } from "../../utils/generalUtils";

type Props = {
    coin: CoinGeneralInfo;
};

const BiggestPuusher: React.FC<Props> = ({ coin }) => {

    const navigate = useNavigate();

    const navToCoin = (coinAddress: string) => {
        navigate(`/coin/${coinAddress}`);
    };

    const openAddressExplorer = (event: React.MouseEvent<HTMLDivElement>, address: string) => {
        event.stopPropagation();
        window.open(`https://cronoscan.com/address/${address}`, '_blank');
    }

    // Format the lastUpdated date to a more human-readable format
    const formattedLastUpdated = format(new Date(coin.lastUpdated), 'PPPpp');

    return (
        <div className="BiggestPuusher-container">
            {/* <img className='BiggestPuusher-title' src={'https://puushdabutton-web-images.s3.amazonaws.com/biggestpuusher.png'} alt='' /> */}
            <img className='BiggestPuusher-title' src={'https://puushdabutton-web-images.s3.amazonaws.com/bp.gif'} alt='' />
            <div className="BiggestPuusher-item clicky" onClick={() => navToCoin(coin.coinAddress)}>
                <div className="BiggestPuusher-image-container">
                    <img className='BiggestPuusher-image' src={coin.metadata?.imageURL} alt="Coin" />
                </div>
                <div className="BiggestPuusher-info">
                    <div
                        className='BiggestPuusher-createdBy'
                        onClick={(event) => openAddressExplorer(event, coin.creatorAddress)}
                    >
                        {getCroImage(coin.chainId)}Created by {coin.creatorAddress.slice(-6)}</div>
                    <div className='BiggestPuusher-updatedAt'>Updated {formattedLastUpdated}</div>
                    <div className='BiggestPuusher-coinsForSale'>{getPercentageSold(coin.fundsCoinBalance, coin.puushdotfunVersion)}% SOLD</div>
                    <div className='BiggestPuusher-description'>
                        {coin.metadata?.name} ({coin.metadata?.ticker})
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BiggestPuusher;