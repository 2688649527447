// src/hooks/useWallet.ts
import { useState, useEffect, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { ConnectorNames, connectors } from '../web3/connectors';
import { ethers } from 'ethers';
import signMessage from '../utils/signMessage';
import signChatMessage from '../utils/signChatMessage';
import puushdotApi from '../requests/puushdotfunApi';
import PuushdotInstance from '../web3/puushdotInstance';
import PuushdotV101Instance from '../web3/puushdotV101Instance';
import PuushdotV102Instance from '../web3/puushdotV102Instance';
import SpotlightInstance from '../web3/spotlightInstance';
import erc20Abi from '../web3/contracts/erc20Abi';
import PuushdotWinInstance from '../web3/puushdotWinInstance';
import { getPuushdotFunContractAddress, getPuushdotFunV101ContractAddress, getPuushdotFunV102ContractAddress, getPuushdotFunV103ContractAddress, getPuushdotLiquidityV103ContractAddress, getSpotlightContractAddress } from '../utils/multiChainUtils';
import chatApi from '../requests/chatApi';
import PuushdotV103Instance from '../web3/puushdotV103Instance';
import PuushdotLiquidityV103Instance from '../web3/puushdotLiquidityV103Instance';

const useWallet = () => {
    const { active, account, library, activate, deactivate, chainId } = useWeb3React();
    const [authenticated, setAuthenticated] = useState(false);
    const [chatLoggedIn, setChatLoggedIn] = useState(false);
    const [authFlag, setAuthFlag] = useState(false);
    const [loading, setLoading] = useState(false);
    const [puushdotInstance, setPuushdotInstance] = useState<PuushdotInstance | null>(null);
    const [puushdotV101Instance, setPuushdotV101Instance] = useState<PuushdotV101Instance | null>(null);
    const [puushdotV102Instance, setPuushdotV102Instance] = useState<PuushdotV102Instance | null>(null);
    const [puushdotV103Instance, setPuushdotV103Instance] = useState<PuushdotV103Instance | null>(null);
    const [puushdotWinInstance, setPuushdotWinInstance] = useState<PuushdotWinInstance | null>(null);
    const [puushdotLiquidityV103Instance, setPuushdotLiquidityV103Instance] = useState<PuushdotLiquidityV103Instance | null>(null);
    const [spotlightInstance, setSpotlightInstance] = useState<SpotlightInstance | null>(null);
    const [balance, setBalance] = useState<string>('0');
    const [coinBalance, setCoinBalance] = useState<string>('0');
    const [coinAllowance, setCoinAllowance] = useState<string>('0');
    const [connectorName, setConnectorName] = useState<ConnectorNames>('injected');

    const connectWallet = useCallback(async (connectorName: ConnectorNames) => {
        try {
            await activate(connectors[connectorName], (error) => { disconnectWallet(); });
            setConnectorName(connectorName);
        } catch (ex) {
            console.error("Error connecting wallet:", ex);
            setAuthenticated(false);
            setLoading(false);

        }
    }, [activate]);

    useEffect(() => {
        const storedAccount = localStorage.getItem('account');
        const storedConnectorName = localStorage.getItem('connectorName') as ConnectorNames;
        if (storedAccount && storedConnectorName) {
            connectWallet(storedConnectorName);
        }
        puushdotApi.checkUserIsLoggedIn()
            .then(response => {
                if (response.message === 'User is logged in') {
                    setAuthenticated(true);
                }
            })
            .catch(() => {
                setAuthenticated(false);
            });
    }, [connectWallet]);

    useEffect(() => {
        if (!account) return;
        localStorage.setItem('account', account!);
        localStorage.setItem('connectorName', connectorName);
    }, [account, connectorName]);

    useEffect(() => {
        if (!library || !account || !chainId) return;
        const initializeInstance = async () => {
            try {
                const provider = new ethers.BrowserProvider(library.provider);
                const instance = await PuushdotInstance.createInstance(provider, getPuushdotFunContractAddress(chainId));
                setPuushdotInstance(instance);
            } catch (ex) {
                console.error("Error initializing puushdot instance:", ex);
            };
        };
        const initializeV101Instance = async () => {
            try {
                const provider = new ethers.BrowserProvider(library.provider);
                const instance = await PuushdotV101Instance.createInstance(provider, getPuushdotFunV101ContractAddress(chainId));
                setPuushdotV101Instance(instance);
            } catch (ex) {
                console.error("Error initializing puushdot V101 instance:", ex);
            };
        };
        const initializeV102Instance = async () => {
            try {
                const provider = new ethers.BrowserProvider(library.provider);
                const instance = await PuushdotV102Instance.createInstance(provider, getPuushdotFunV102ContractAddress(chainId));
                setPuushdotV102Instance(instance);
            } catch (ex) {
                console.error("Error initializing puushdot V102 instance:", ex);
            };
        };
        const initializeV103Instance = async () => {
            try {
                const provider = new ethers.BrowserProvider(library.provider);
                const instance = await PuushdotV103Instance.createInstance(provider, getPuushdotFunV103ContractAddress(chainId));
                setPuushdotV103Instance(instance);
            } catch (ex) {
                console.error("Error initializing puushdot V102 instance:", ex);
            };
        };
        const initializeSpotlightInstance = async () => {
            try {
                const provider = new ethers.BrowserProvider(library.provider);
                const instance = await SpotlightInstance.createInstance(provider, getSpotlightContractAddress(chainId));
                setSpotlightInstance(instance);
            } catch (ex) {
                console.error("Error initializing spotlight instance:", ex);
            }
        };
        const initializePuushdotWinInstance = async () => {
            try {
                const provider = new ethers.BrowserProvider(library.provider);
                const instance = await PuushdotWinInstance.createInstance(provider);
                setPuushdotWinInstance(instance);
            } catch (ex) {
                console.error("Error initializing puushdot win instance:", ex);
            };
        };

        const initializePuushdotLiquidityV103Instance = async () => {
            try {
                const provider = new ethers.BrowserProvider(library.provider);
                const instance = await PuushdotLiquidityV103Instance.createInstance(provider, getPuushdotLiquidityV103ContractAddress(chainId));
                setPuushdotLiquidityV103Instance(instance);
            } catch (ex) {
                console.error("Error initializing puushdot liquidity V103 instance:", ex);
            };
        };

        initializeSpotlightInstance();
        initializeInstance();
        initializeV101Instance();
        initializeV102Instance();
        initializeV103Instance();
        initializePuushdotWinInstance();
        initializePuushdotLiquidityV103Instance();

    }, [library, account, chainId]);

    useEffect(() => {
        if (!account || !library) return;
        const fetchBalance = async () => {
            const provider = new ethers.BrowserProvider(library.provider);
            const balance = await provider.getBalance(account);
            setBalance(balance.toString());
        };
        fetchBalance();
    }, [account, library]);

    const connectWithAuth = async (connectorName: ConnectorNames) => {
        setLoading(true);
        setAuthenticated(false);
        try {
            await connectWallet(connectorName);
            setAuthFlag(true);
        } catch (ex) {
            console.error("Error connecting wallet and authenticating user:", ex);
        }
    };

    const authenticateUser = async (account: string, library: any) => {
        try {
            const provider = new ethers.BrowserProvider(library.provider);
            const data = await signMessage(provider, account);
            if (data && data.success) {
                console.log('Authentication successful:', data);
                setAuthenticated(true);
            } else {
                console.log('Authentication failed');
                setAuthenticated(false);
            }
        } catch (ex) {
            console.error("Error signing message:", ex);
            setAuthenticated(false);
        }
    };

    const loginChat = async () => {
        if (!account || !library) return console.log(`Can't login to chat yet`);
        try {
            const provider = new ethers.BrowserProvider(library.provider);
            const data = await signChatMessage(provider, account);
            if (data && data.success) {
                console.log('Chat login successful:', data);
                setChatLoggedIn(true);
            } else {
                console.log('Chat login failed');
                setChatLoggedIn(false);
            }
        } catch (ex) {
            console.error("Error signing message:", ex);
            setChatLoggedIn(false);
        }
    };

    const disconnectWallet = async () => {
        try {
            deactivate();
            localStorage.removeItem('account');
            localStorage.removeItem('connectorName');
            setLoading(false);
            setAuthenticated(false);
            setConnectorName('injected');
        } catch (ex) {
            console.error("Error disconnecting wallet:", ex);
        }
    };

    const checkChatLogin = async () => {
        if (!account) return console.log(`Can't check chat login yet`);
        const response = await chatApi.isLoggedIn(account);
        if (response?.data.message === 'User is logged in' && response?.status === 200) {
            setChatLoggedIn(true);
        } else {
            setChatLoggedIn(false);
        }
    };

    const getCoinCreationInstance = (): PuushdotV102Instance | PuushdotV103Instance | null => {
        if (!library || !account || !chainId) return null;
        switch (chainId) {
            case 25:
                return puushdotV103Instance;
            case 388:
                return puushdotV103Instance;
            default:
                return null;
        };
    };

    useEffect(() => {
        if (!account || !library || !authFlag) return console.log(`Can't auth yet`);
        setLoading(true);
        setAuthFlag(false);
        authenticateUser(account, library).then(() => setLoading(false)).catch(() => setLoading(false));
    }, [authFlag, account, library]);

    const getCoinBalance = async (coinAddress: string) => {
        if (!library || !account) return;
        const provider = new ethers.BrowserProvider(library.provider);
        const coinContract = new ethers.Contract(coinAddress, erc20Abi, provider);
        const balance = await coinContract.balanceOf(account);
        setCoinBalance(balance.toString());
    };

    const getCoinAllowance = async (coinAddress: string, spender: string) => {
        if (!library || !account) return;
        const provider = new ethers.BrowserProvider(library.provider);
        const coinContract = new ethers.Contract(coinAddress, erc20Abi, provider);
        const allowance = await coinContract.allowance(account, spender);
        setCoinAllowance(allowance.toString());
    };

    const approveCoin = async (coinAddress: string, spender: string, amount: string) => {
        if (!library || !account) return;
        const provider = new ethers.BrowserProvider(library.provider);
        const signer = await provider.getSigner();
        const coinContract = new ethers.Contract(coinAddress, erc20Abi, signer);
        const tx = await coinContract.approve(spender, amount);
        await tx.wait();
    };

    const refreshBalances = async (coinAddress: string, spender: string) => {
        if (!account || !library) return;
        const provider = new ethers.BrowserProvider(library.provider);
        const balance = await provider.getBalance(account);

        const coinContract = new ethers.Contract(coinAddress, erc20Abi, provider);
        const coinBalance = await coinContract.balanceOf(account);

        const allowance = await coinContract.allowance(account, spender);

        setCoinAllowance(allowance.toString());
        setCoinBalance(coinBalance.toString());
        setBalance(balance.toString());
    };

    return {
        isConnected: active,
        isAuthenticated: authenticated,
        account,
        balance,
        coinBalance,
        coinAllowance,
        connectWithAuth,
        disconnectWallet,
        loading,
        puushdotInstance,
        puushdotV101Instance,
        puushdotV102Instance,
        puushdotV103Instance,
        spotlightInstance,
        puushdotWinInstance,
        getCoinBalance,
        getCoinAllowance,
        approveCoin,
        refreshBalances,
        chainId,
        chatLoggedIn,
        loginChat,
        checkChatLogin,
        getCoinCreationInstance,
        puushdotLiquidityV103Instance,
    };
};

export default useWallet;
