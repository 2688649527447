// src/hooks/usePublicReads.ts
import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import PublicPuushdotLiquidityV101Instance from '../web3/publicInstances/publicPuushdotLiquidityV101Instance';
import PublicPuushdotLiquidityV103Instance from '../web3/publicInstances/publicPuushdotLiquidityV103Instance';
import { getPuushdotLiquidityV101ContractAddress, getPuushdotLiquidityV103ContractAddress } from '../utils/multiChainUtils';

const usePublicReads = (chainId: number) => {
    const publicRpcUrlsByChainId: { [key: number]: string } = {
        25: 'https://rpc.vvs.finance',
        388: 'https://mainnet.zkevm.cronos.org',
    };

    const [publicLiquidityV101Instance, setPublicLiquidityV101Instance] = useState<PublicPuushdotLiquidityV101Instance | null>(null);
    const [publicLiquidityV103Instance, setPublicLiquidityV103Instance] = useState<PublicPuushdotLiquidityV103Instance | null>(null);

    useEffect(() => {
        const initializeLiquidityV101Instance = async () => {
            try {
                const rpcUrl = publicRpcUrlsByChainId[chainId];
                if (!rpcUrl) {
                    console.error(`No RPC URL found for chain ID ${chainId}`);
                    return;
                }

                const provider = new ethers.JsonRpcProvider(rpcUrl);
                const instance = await PublicPuushdotLiquidityV101Instance.createInstance(provider, getPuushdotLiquidityV101ContractAddress(chainId));
                setPublicLiquidityV101Instance(instance);
            } catch (ex) {
                console.error("Error initializing Puushdot Liquidity V101 instance:", ex);
            }
        };

        const initializeLiquidityV103Instance = async () => {
            try {
                const rpcUrl = publicRpcUrlsByChainId[chainId];
                if (!rpcUrl) {
                    console.error(`No RPC URL found for chain ID ${chainId}`);
                    return;
                }

                const provider = new ethers.JsonRpcProvider(rpcUrl);
                const instance = await PublicPuushdotLiquidityV103Instance.createInstance(provider, getPuushdotLiquidityV103ContractAddress(chainId));
                setPublicLiquidityV103Instance(instance);
            } catch (ex) {
                console.error("Error initializing Puushdot Liquidity V103 instance:", ex);
            }
        };

        initializeLiquidityV101Instance();
        initializeLiquidityV103Instance();
    }, [chainId]);

    return {
        publicLiquidityV101Instance,
        publicLiquidityV103Instance,
    };
};

export default usePublicReads;
