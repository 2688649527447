// src/App.tsx
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header/Header';
import StartNewCoin from './nav/StartNewCoin/StartNewCoin';
import Home from './nav/Home/Home';
import Coin from './nav/Coin/Coin';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Footer from './components/Footer/Footer';
import BuySpotlight from './nav/BuySpotlight/BuySpotlight';
// import Announcement from './components/Announcement/Announcement';
import TradingRewards from './nav/TradingRewards/TradingRewards';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the styles for react-toastify


function App() {
    return (
        <Router>
            <ScrollToTop />
            <div className="App">
                <ToastContainer />
                {/* <Announcement /> */}
                <Header />
                <div className="App-content">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/start-new-coin" element={<StartNewCoin />} />
                        <Route path="/coin/:coinAddress" element={<Coin />} />
                        <Route path="/buy-spotlight" element={<BuySpotlight />} />
                        <Route path="/trading-rewards" element={<TradingRewards />} />
                    </Routes>
                </div>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
