import React, { useEffect, useState } from 'react';
import { CoinGeneralInfo, TransactionLog } from '../../providers/CoinListProvider';
import { toWei } from '../../utils/priceUtils';
import { displayName, openAddressExplorer, openURLInNew } from '../../utils/generalUtils';
import BigNumber from 'bignumber.js';
import './HolderList.css';
import boomerApi, { TokenHolderData } from '../../requests/boomerApi';

type HolderListProps = {
    coin: CoinGeneralInfo;
    logs: TransactionLog[];
    holderInfo: TokenHolderData | undefined;

};

const HolderList: React.FC<HolderListProps> = ({ coin, logs, holderInfo }) => {

    const [holdersFromLogs, setHoldersFromLogs] = useState<{ address: string, balance: bigint }[]>([]);
    const [holdersFromApi, setHoldersFromApi] = useState<{ address: string, balance: string, percent: string }[]>([]);
    const [totalHoldersFromLogs, setTotalHoldersFromLogs] = useState<number>(0);
    const [totalHoldersFromApi, setTotalHoldersFromApi] = useState<number>(0);

    const maxSupply = BigInt(toWei('1000000000'));

    const getApiCoinInfo = async (holderInfo: TokenHolderData) => {
        if (!holderInfo) {
            return;
        }
        setHoldersFromApi(holderInfo.holders);
        setTotalHoldersFromApi(Number(holderInfo.holder_count));
    };

    // Use the txLogs to build a top 20 holder list with % of the supply they own
    useEffect(() => {
        const holderMap = new Map<string, bigint>();

        logs.forEach(log => {
            if (log.transactionType === 'buy') {
                holderMap.set(log.userAddress, (holderMap.get(log.userAddress) || BigInt(0)) + BigInt(log.coinAmount));
                holderMap.set(coin.fundsAddress, BigInt(log.coinBalance));
            }
            if (log.transactionType === 'sell') {
                holderMap.set(log.userAddress, (holderMap.get(log.userAddress) || BigInt(0)) - BigInt(log.coinAmount));
                holderMap.set(coin.fundsAddress, BigInt(log.coinBalance));
            }
        });

        const holderArray = Array.from(holderMap.entries()).map(([address, balance]) => ({ address, balance }));
        holderArray.sort((a, b) => Number(b.balance - a.balance));

        let totalHoldersTemp = 0;

        holderArray.forEach(holder => {
            if (holder.balance > BigInt(0)) {
                totalHoldersTemp++;
            }
        });

        setTotalHoldersFromLogs(totalHoldersTemp);
        setHoldersFromLogs(holderArray.slice(0, 10));
        if (holderInfo) getApiCoinInfo(holderInfo);
    }, [logs, coin, holderInfo]);

    const holderUrl = `https://cronoscan.com/token/${coin.coinAddress}#balances`

    return (
        <div style={{ textAlign: 'left', width: '100%', maxWidth: '350px' }}>
            <span className='med-text text-clicky' onClick={(e) => openURLInNew(e, holderUrl)}>[Holders (Live)]</span>
            <div className='full-row' style={{ justifyContent: 'center' }}>
                <div className='HolderList'>
                    <div className='med-text'>
                        Buyers
                    </div>
                    <div className='small-text text-grey'>
                        Showing top {holdersFromLogs.length} of {totalHoldersFromLogs}
                    </div>
                    {holdersFromLogs.map((holder, index) => {
                        const balanceBN = new BigNumber(holder.balance.toString());
                        const percentSupplyOwned = balanceBN.dividedBy(maxSupply.toString()).multipliedBy(100).toFixed(2);

                        return (
                            <div key={holder.address} className='HolderList-row'>
                                <div className='text-white small-text'>{index + 1}.</div>
                                <div className='text-white small-text'>{percentSupplyOwned}%</div>
                                <div
                                    className='text-blue small-text text-clicky'
                                    onClick={(e) => openAddressExplorer(e, holder.address, coin.chainId)}
                                >
                                    {displayName(holder.address)}
                                    {coin.creatorAddress === holder.address && ('👨‍💻')}
                                    {coin.fundsAddress === holder.address && ('🏦')}
                                </div>
                            </div>
                        );
                    })}
                </div>
                {/* <div className='grey-seperator'></div> */}
                {/* <div className='HolderList'>
                    <div className='med-text'>
                        Holders (Delayed)
                    </div>
                    {
                        holdersFromApi && (
                            <>
                                <div className='small-text text-grey'>
                                    Showing top {holdersFromApi.length} of {totalHoldersFromApi}
                                </div>
                                {holdersFromApi.map((holder, index) => {
                                    return (
                                        <div key={holder.address} className='HolderList-row'>
                                            <div className='text-white small-text'>{index + 1}.</div>
                                            <div className='text-white small-text'>{(Number(holder.percent) * 100).toFixed(2)}%</div>
                                            <div
                                                className='text-blue small-text text-clicky'
                                                onClick={(e) => openAddressExplorer(e, holder.address)}
                                            >
                                                {displayName(holder.address)}
                                                {coin.creatorAddress.toLowerCase() === holder.address.toLowerCase() && ('👨‍💻')}
                                                {coin.fundsAddress.toLowerCase() === holder.address.toLowerCase() && ('🏦')}
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        )
                    }
                </div> */}
            </div>
        </div>
    );
};

export default HolderList;