import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { io, Socket } from 'socket.io-client';
import config from '../config';

interface ChatWebSocketProviderProps {
    children: ReactNode;
}

export interface ChatWebSocketContextType {
    socket: Socket | null;
    joinChatRoom: (chatId: string) => void;
    leaveChatRoom: (chatId: string) => void;
}

const ChatWebSocketContext = createContext<ChatWebSocketContextType | null>(null);

export const ChatWebSocketProvider: React.FC<ChatWebSocketProviderProps> = ({ children }) => {
    const [socket, setSocket] = useState<Socket | null>(null);

    useEffect(() => {
        const newSocket = io(config.CHAT_BASE_WS_URL, {
            reconnectionAttempts: 5,
            reconnectionDelay: 1000,
        });
        setSocket(newSocket);

        newSocket.on('connect', () => {
            console.log('Connected to Chat WebSocket server');
        });

        newSocket.on('disconnect', (reason) => {
            console.log(`Disconnected from Chat WebSocket server: ${reason}`);
            if (reason === 'io server disconnect') {
                newSocket.connect();
            }
        });

        return () => {
            newSocket.close();
        };
    }, []);

    const joinChatRoom = (chatId: string) => {
        if (socket) {
            socket.emit('joinChatRoom', chatId);
        }
    };

    const leaveChatRoom = (chatId: string) => {
        if (socket) {
            socket.emit('leaveChatRoom', chatId);
        }
    };

    return (
        <ChatWebSocketContext.Provider value={{ socket, joinChatRoom, leaveChatRoom }}>
            {children}
        </ChatWebSocketContext.Provider>
    );
};

export const useChatWebSocket = () => {
    return useContext(ChatWebSocketContext);
};
