// FILE: src/components/Header/Modal.tsx
import React from 'react';
import './Modal.css';

type ModalProps = {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
};

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div className="Modal-overlay" onClick={onClose}>
            <div className="Modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="Modal-close" onClick={onClose}>
                    &times;
                </button>
                {children}
            </div>
        </div>
    );
};

export default Modal;
