import { useParams } from "react-router-dom";
import { CoinGeneralInfo, useCoinList } from "../../providers/CoinListProvider";
import { shortenUrl } from '../../utils/generalUtils';
import { useEffect, useState } from 'react';
import './Coin.css';
import GraphSection from "./GraphSection";
import { useWebSocket, WebSocketContextType } from "../../providers/WebSocketProvider";
import TxLogList from "../../components/TxLogList/TxLogList";
import HolderList from "./HolderList";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import WarningModal from "../../components/WarningModal/WarningModal";
import boomerApi, { GetCoinInfoResponse } from "../../requests/boomerApi";
import CoinGraduationInfo from "../../components/CoinGraduationInfo/CoinGraduationInfo";
import Chat from "../../components/Chat/Chat";
import useWallet from "../../hooks/useWallet";
import CreatorPanel from "../../components/CreatorPanel/CreatorPanel";

const Coin = () => {
    // Get the coin address from the URL
    const { coinAddress } = useParams<{ coinAddress: string }>();
    const { getCoinByAddress, txLogs, candleData } = useCoinList();
    const { joinCoinRoom, leaveCoinRoom } = useWebSocket() as WebSocketContextType;

    const [view, setView] = useState<'Thread' | 'Logs' | 'Thread (Old)'>('Thread');
    const [warningUrl, setWarningUrl] = useState<string | null>(null);
    const [apiCoinInfo, setApiCoinInfo] = useState<GetCoinInfoResponse | undefined>(undefined);

    const { puushdotLiquidityV103Instance, chainId } = useWallet();

    const getApiCoinInfo = async (coinAddress: string) => {
        const apiCoinInfo: GetCoinInfoResponse | undefined = await boomerApi.getCoinInfo(coinAddress);
        if (!apiCoinInfo) {
            return;
        }
        setApiCoinInfo(apiCoinInfo);
    };

    useEffect(() => {
        if (coinAddress) {
            joinCoinRoom(coinAddress);
            getApiCoinInfo(coinAddress);

            return () => {
                leaveCoinRoom(coinAddress);
            };
        }
    }, [coinAddress, joinCoinRoom, leaveCoinRoom, chainId, puushdotLiquidityV103Instance]);

    // Ensure coinAddress is defined before using it
    if (!coinAddress) {
        return <div>COIN 404, PLEASE TRY AGAIN</div>;
    }

    const coin: CoinGeneralInfo | undefined = getCoinByAddress(coinAddress);

    if (!coin) {
        return <div>COIN 404, PLEASE TRY AGAIN</div>;
    }

    const handleOpenUrl = (url: string) => {
        setWarningUrl(url);
    };

    const confirmOpenUrl = () => {
        if (warningUrl) {
            window.open(warningUrl, '_blank');
            setWarningUrl(null);
        }
    };

    const cancelOpenUrl = () => {
        setWarningUrl(null);
    };

    // console.log('Coins in funds:', coin.fundsCoinBalance);

    return (
        <div className='Coin-container'>
            {warningUrl && (
                <WarningModal
                    url={warningUrl}
                    onConfirm={confirmOpenUrl}
                    onCancel={cancelOpenUrl}
                />
            )}
            <GraphSection coin={coin} allCandleData={candleData} />
            <div className='Coin-splitrow'>
                <div className='full-row'>
                    <div style={{ width: '100%' }}>
                        <div className="full-row gapped" style={{ margin: '5px 0px' }}>
                            <button className={`s-button ${view === 'Thread' ? 'active' : ''}`} onClick={() => setView('Thread')}>Thread</button>
                            <button className={`s-button ${view === 'Logs' ? 'active' : ''}`} onClick={() => setView('Logs')}>Logs</button>
                        </div>
                        {
                            view === 'Thread' &&
                            <div className='Coin-thread'>
                                <Chat coinAddress={coinAddress} />
                            </div>
                        }
                        {
                            view === 'Logs' &&
                            <TxLogList logs={txLogs} />
                        }
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px' }}>
                    <div className="full-row">
                        <ProgressBar coin={coin} />
                    </div>
                    <div className="full-row">
                        <CreatorPanel coin={coin} />
                    </div>
                    <div className="full-row">
                        <CoinGraduationInfo coin={coin} />
                    </div>
                    <div className='Coin-info'>
                        <div className="full-row">
                            <div style={{ flex: '1' }}>
                                {coin.metadata?.imageURL && <img src={coin.metadata.imageURL || 'https://via.placeholder.com/000'} alt={coin.metadata.name} style={{ width: '100%' }} />}
                            </div>
                            <div className='full-row-column' style={{ flex: '1' }}>
                                <div className='med-text'>{coin.metadata?.name} ({coin.metadata?.ticker})</div>
                                <div className='small-text text-clicky underlined' onClick={() => handleOpenUrl(coin.metadata?.twitter || '')}>{shortenUrl(coin.metadata?.twitter)}</div>
                                <div className='small-text text-clicky underlined' onClick={() => handleOpenUrl(coin.metadata?.telegram || '')}>{shortenUrl(coin.metadata?.telegram)}</div>
                                <div className='small-text text-clicky underlined' onClick={() => handleOpenUrl(coin.metadata?.website || '')}>{shortenUrl(coin.metadata?.website)}</div>
                                <div className='xsmall-text' style={{ marginTop: '5px' }}>Description: {coin.metadata?.description}</div>
                            </div>
                        </div>
                    </div>
                    <HolderList coin={coin} logs={txLogs} holderInfo={apiCoinInfo?.holderInfo} />
                </div>
            </div>
        </div>
    );
};

export default Coin;