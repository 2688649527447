import { ethers } from 'ethers';
import PuushdotLiquidityV101 from '../contracts/PuushdotLiquidityV101.json';

class PublicPuushdotLiquidityV101Instance {
    private contract: ethers.Contract;
    public contractAddress: string;

    constructor(contract: ethers.Contract, contractAddress: string) {
        this.contract = contract;
        this.contractAddress = contractAddress;
    }

    public static async createInstance(provider: ethers.JsonRpcProvider, contractAddress: string): Promise<PublicPuushdotLiquidityV101Instance> {
        const contract = new ethers.Contract(contractAddress, PuushdotLiquidityV101.abi, provider);
        return new PublicPuushdotLiquidityV101Instance(contract, contractAddress);
    }

    public async viewCoinTexts(coinAddress: string): Promise<any> {
        try {
            return await this.contract.coinTexts(coinAddress);
        } catch (error) {
            console.error('Error getting coin texts:', error);
            throw error;
        }
    }

    public async viewCoinRouterId(coinAddress: string): Promise<bigint> {
        try {
            return await this.contract.coinRouterId(coinAddress);
        } catch (error) {
            console.error('Error getting router ID:', error);
            throw error;
        }
    }

    public async viewVVSRouter(): Promise<string> {
        try {
            return await this.contract.vvsRouter();
        } catch (error) {
            console.error('Error getting VVS Router:', error);
            throw error;
        }
    }

    public async viewEBRouter(): Promise<string> {
        try {
            return await this.contract.ebRouter();
        } catch (error) {
            console.error('Error getting EB Router:', error);
            throw error;
        }
    }

    public async viewRouter(coinAddress: string): Promise<string> {
        try {
            return await this.contract.getCoinGraduationRouter(coinAddress);
        } catch (error) {
            console.error('Error getting Router:', error);
            throw error;
        }
    }
}

export default PublicPuushdotLiquidityV101Instance;
