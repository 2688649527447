// src/web3/connectors.ts
import { InjectedConnector } from '@web3-react/injected-connector';
import { DeFiWeb3Connector } from '@deficonnect/web3-connector'
import { WalletConnectV2Connector } from './connectors/WalletConnectV2Connector';

export const injected = new InjectedConnector({
    supportedChainIds: [25, 388, 1337] // mainnet and testnets
});

export const defiConnector = new DeFiWeb3Connector({
    supportedChainIds: [25, 388, 1337],
    appName: 'https://www.puush.fun/',
    chainType: 'eth', // only support 'eth' for DeFiWeb3Connector
    chainId: '25', // for eth is 1
    rpcUrls: {
        25: 'https://evm-cronos.crypto.org/',
        388: 'https://mainnet.zkevm.cronos.org/',
        1337: 'http://localhost:8545'
    },
});

export const walletConnect = new WalletConnectV2Connector(25);


export type ConnectorNames = 'injected' | 'defiConnector' | 'walletConnect';

export const connectors: { [key in ConnectorNames]: any } = {
    injected,
    defiConnector,
    walletConnect
};