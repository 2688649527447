import { useState, useEffect } from 'react';
import boomerApi from '../requests/boomerApi';

const REFRESH_INTERVAL = 60000; // 1 minute in milliseconds

export const useGlobalInfo = () => {
    const [globalInfo, setGlobalInfo] = useState<{ PUUSHBurned: string; CROUSD: number } | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchGlobalInfo = async () => {
        try {
            const data = await boomerApi.getGlobalInfo();
            setGlobalInfo(data);
        } catch (error) {
            setError('Failed to get global info');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchGlobalInfo();

        const interval = setInterval(() => {
            fetchGlobalInfo();
        }, REFRESH_INTERVAL);

        return () => clearInterval(interval); // Clear the interval on component unmount
    }, []);

    return { globalInfo, loading, error };
};