import React, { useEffect, useState, useCallback, useRef } from 'react';
import useWallet from '../../hooks/useWallet';
import './Chat.css';
import { ChatWebSocketContextType, useChatWebSocket } from '../../providers/ChatWebsocketProvider';
import chatApi from '../../requests/chatApi';
import { displayName, openAddressExplorer } from '../../utils/generalUtils';
import useLoadingWithDots from '../../hooks/useLoadingWithDots';

type ChatProps = {
    coinAddress: string;
};

type Message = {
    chatId: string;
    sender: string;
    message: string;
    "timestamp#sender": string; // timestamp#sender (string, formatted as ISO 8601 timestamp concatenated with sender)
    image: string; // Optional image URL
};

const Chat: React.FC<ChatProps> = ({ coinAddress }) => {
    const {
        account,
        chatLoggedIn,
        loginChat,
        checkChatLogin,
        chainId,
    } = useWallet();
    const { socket, joinChatRoom, leaveChatRoom } = useChatWebSocket() as ChatWebSocketContextType;

    const [messages, setMessages] = useState<Message[]>([]);
    const [newMessage, setNewMessage] = useState<string>('');
    const [image, setImage] = useState<File | null>(null);
    const [fileError, setFileError] = useState<string>('');
    const [animateQueue, setAnimateQueue] = useState<string[]>([]); // Queue for animations
    const [loading, setLoading] = useState<boolean>(false); // Loading state
    const [postLoading, setPostLoading] = useState<boolean>(false); // Loading state for posting messages
    const [serverErrorText, setServerErrorText] = useState<string>(''); // Error text from the server

    const [currentMessages, setCurrentMessages] = useState<Message[]>([]);
    const [deferredMessages, setDeferredMessages] = useState<Message[]>([]); // New state for deferred message

    const fileInputRef = useRef<HTMLInputElement>(null);

    const loadingText = useLoadingWithDots(loading);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 30; // Adjust the number of items per page
    const totalPages = Math.ceil(messages.length / itemsPerPage);

    const handleFirstPage = () => setCurrentPage(1);
    const handlePrevPage = () => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
    const handleNextPage = () => setCurrentPage(currentPage < totalPages ? currentPage + 1 : totalPages);
    const handleLastPage = () => setCurrentPage(totalPages);

    // Update currentMessages when messages or currentPage changes
    useEffect(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        setCurrentMessages(messages.slice(startIndex, startIndex + itemsPerPage));

        // If on the first page, show deferred messages
        if (currentPage === 1 && deferredMessages.length > 0) {
            setMessages((prevMessages) => [...deferredMessages, ...prevMessages]);
            setDeferredMessages([]); // Clear deferred messages
        }
    }, [messages, currentPage, deferredMessages]);

    // Join chat room on mount, leave on unmount
    useEffect(() => {
        joinChatRoom(coinAddress);

        return () => {
            leaveChatRoom(coinAddress);
        };
    }, [joinChatRoom, leaveChatRoom, coinAddress]);

    // Check chat login status on mount
    useEffect(() => {
        setLoading(true); // Start loading
        checkChatLogin()
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    }, [account]);

    // Fetch chat messages on mount, handle new messages
    useEffect(() => {
        if (socket) {
            socket.on('initChat', (chatMessages: Message[]) => {
                setMessages(chatMessages.reverse());
            });

            socket.on('newMessage', (message: Message) => {
                if (currentPage === 1) {
                    setMessages((prevMessages) => [message, ...prevMessages]);
                    setAnimateQueue((prevQueue) => [...prevQueue, message['timestamp#sender']]);
                } else {
                    setDeferredMessages((prevDeferred) => [message, ...prevDeferred]);
                }
            });

            return () => {
                socket.off('initChat');
                socket.off('newMessage');
            };
        }
    }, [socket, currentPage]);

    // Handle animation queue
    useEffect(() => {
        if (animateQueue.length > 0) {
            const currentId = animateQueue[0];
            setTimeout(() => {
                setAnimateQueue((prevQueue) => prevQueue.slice(1));
            }, 500);
        }
    }, [animateQueue]);

    const handleLogin = async () => {
        setLoading(true); // Start loading

        try {
            await loginChat();

            setLoading(false); // Stop loading
        } catch (error) {
            console.error('Failed to log in:', error);
            setLoading(false); // Stop loading
        }
    };

    const handleLogout = async () => {
        try {
            setLoading(true); // Start loading
            await chatApi.logout();
            await checkChatLogin();
            setLoading(false); // Stop loading
        } catch (error) {
            console.error('Failed to log out:', error);
            setLoading(false); // Stop loading
        };
    };

    const handlePostMessage = useCallback(async () => {
        if (newMessage.trim() === '' || !account) return;

        setPostLoading(true); // Start loading

        try {
            const formData = new FormData();
            formData.append('chatId', coinAddress);
            formData.append('sender', account);
            formData.append('message', newMessage);

            if (image) {
                formData.append('image', image);
            }

            const response = await chatApi.postMessage(formData);

            if (response === 429) {
                console.error('Rate limit exceeded');
                setServerErrorText('Rate limit exceeded, please try again in a few minutes.');
            } // Handle rate limit error

            setNewMessage('');
            setImage(null);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        } catch (error) {
            console.error('Failed to send message:', error);
        } finally {
            setPostLoading(false); // Stop loading
        }
    }, [newMessage, image, coinAddress, account]);

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;
        if (files && files.length > 0) {
            const file = files[0];

            if (file.size > 4 * 1024 * 1024) {
                setFileError('File size must be less than 4 MB');
                return;
            }

            const validMimeTypes = ['image/jpeg', 'image/png'] as const;
            if (!validMimeTypes.includes(file.type as any)) {
                setFileError('File type must be PNG or JPG');
                return;
            }

            setImage(file);
            setFileError('');
        }
    };

    const checkDisabled = loading || postLoading || !chatLoggedIn || (serverErrorText !== '');

    return (
        <div className="Chat">
            <div className='full-row left relative'>
                <div className="Chat-input">
                    {
                        account && chatLoggedIn &&
                        <div className='full-row-between relative small-text'>
                            <div>
                                Logged in as <span className='text-blue text-clicky' onClick={(e) => openAddressExplorer(e, account, chainId?.toString())}>{displayName(account)}</span>
                            </div>
                            <button onClick={handleLogout}>Chat logout</button>
                        </div>
                    }
                    <textarea
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        placeholder="Type your message..."
                        rows={3} // Set to show 3 lines by default
                        maxLength={1000} // Set a max length for the textarea
                        disabled={checkDisabled} // Disable when loading or not logged in
                    />
                    <div className='full-row-between'>
                        <input
                            type="file"
                            accept="image/jpeg,image/png"
                            ref={fileInputRef}
                            onChange={handleImageChange}
                            disabled={checkDisabled} // Disable when loading or not logged in
                        />
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            {fileError && <span className="small-text text-red">{fileError}</span>} {/* Display error message */}
                            <button className='Chat-post-button' onClick={handlePostMessage} disabled={checkDisabled || !!fileError}>
                                {postLoading ? 'Posting...' : 'Post'}
                            </button>
                        </div>
                    </div>
                </div>
                {
                    (!account || !chatLoggedIn || (serverErrorText !== '')) && (
                        <div className="Chat-input-overlay">
                            {
                                serverErrorText !== '' ?
                                    <div className="full-row-center">
                                        <span className="text-red">
                                            {serverErrorText}
                                        </span>
                                    </div>
                                    :
                                    loading ?
                                        <div className="full-row-center">
                                            < span className="med-text">{loadingText}</span>
                                        </div> :
                                        <>
                                            {
                                                !account ?
                                                    <span>Connect wallet to log in to chat.</span> : !chatLoggedIn ?
                                                        <button className="login-button smed-text" onClick={handleLogin}>Log in chat</button> : null
                                            }
                                        </>


                            }
                        </div>
                    )
                }
            </div >
            <div className="pagination-controls">
                <button onClick={handleFirstPage} disabled={currentPage === 1}>{'<<'}</button>
                <button onClick={handlePrevPage} disabled={currentPage === 1}>{'<'}</button>
                <span>{currentPage} of {totalPages}</span>
                <button onClick={handleNextPage} disabled={currentPage === totalPages}>{'>'}</button>
                <button onClick={handleLastPage} disabled={currentPage === totalPages}>{'>>'}</button>
            </div>
            <div className="Chat-thread">
                {currentMessages.map((msg, index) => (
                    <Message
                        key={index}
                        {...msg}
                        animateId={animateQueue[0]} // Pass the first element of the animation queue
                        chainId={chainId?.toString() || ''}
                    />
                ))}
            </div>
            <div className="pagination-controls">
                <button onClick={handleFirstPage} disabled={currentPage === 1}>{'<<'}</button>
                <button onClick={handlePrevPage} disabled={currentPage === 1}>{'<'}</button>
                <span>{currentPage} of {totalPages}</span>
                <button onClick={handleNextPage} disabled={currentPage === totalPages}>{'>'}</button>
                <button onClick={handleLastPage} disabled={currentPage === totalPages}>{'>>'}</button>
            </div>
        </div >
    );
};

export default Chat;

type MessageProps = {
    chatId: string;
    sender: string;
    message: string;
    "timestamp#sender": string;
    image: string;
    animateId: string;
    chainId: string;
};

const Message: React.FC<MessageProps> = ({ chatId, sender, message, image, "timestamp#sender": timestampSender, animateId, chainId }) => {
    const [timestamp, senderId] = timestampSender.split('#');

    return (
        <div className={`Chat-message ${animateId === timestampSender ? 'animate' : ''}`}>
            <div className='full-row left gapped smed-text'>
                <div className='text-blue text-clicky' onClick={(e) => openAddressExplorer(e, senderId, chainId)}>{displayName(senderId)}</div>
                <div className='text-white'>{new Date(timestamp).toLocaleString()}</div>
            </div>
            <div className='full-row left gapped med-text'>
                {image && (
                    <div className='Chat-img-container'>
                        <img
                            className='Chat-img'
                            src={`https://puushdotfun-chat.s3.amazonaws.com/images/${image}`}
                            alt="attached"
                        />
                    </div>
                )}
                <div>{message}</div>
            </div>
        </div>
    );
};
