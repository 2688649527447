import { ethers } from 'ethers';
import PuushdotLiquidityV103 from './contracts/PuushdotLiquidityV103.json';

class PuushdotLiquidityV103Instance {
    private contract: any;
    public contractAddress: string;

    constructor(contract: any, contractAddress: string) {
        this.contract = contract;
        this.contractAddress = contractAddress;
    }

    public static async createInstance(provider: ethers.BrowserProvider, contractAddress: string): Promise<PuushdotLiquidityV103Instance> {
        const signer = await provider.getSigner();
        const contract = new ethers.Contract(contractAddress, PuushdotLiquidityV103.abi, signer);
        return new PuushdotLiquidityV103Instance(contract, contractAddress);
    }

    public async viewCoinTexts(coinAddress: string): Promise<any> {
        try {
            return await this.contract.coinTexts(coinAddress);
        } catch (error) {
            console.error('Error getting coin texts:', error);
            throw error;
        }
    };

    public async viewCoinRouterId(coinAddress: string): Promise<bigint> {
        try {
            return await this.contract.coinRouterId(coinAddress);
        } catch (error) {
            console.error('Error getting router ID:', error);
            throw error;
        };
    };

    public async viewRouter(id: string): Promise<string> {
        try {
            return await this.contract.routers(id);
        } catch (error) {
            console.error('Error routers:', error);
            throw error;
        }
    };

    public async setCoinGraduationRouterAsCoinCreator(coinAddress: string, id: number): Promise<any> {
        try {
            return await this.contract.setCoinGraduationRouterAsCoinCreator(coinAddress, id);
        } catch (error) {
            console.error('Error setting coin graduation router:', error);
            throw error;
        }
    };

    public async setCoinTextAsCoinCreator(coinAddress: string, text: string): Promise<any> {
        try {
            return await this.contract.setCoinTextAsCoinCreator(coinAddress, text);
        } catch (error) {
            console.error('Error setting coin text:', error);
            throw error;
        }
    };

    public async checkCreatorControlAuthorization(coinAddress: string): Promise<boolean> {
        try {
            return await this.contract.checkCreatorControlAuthorization(coinAddress);
        } catch (error) {
            console.error('Error checking creator control authorization:', error);
            throw error;
        }
    };

    public async viewTimeLimit(): Promise<string> {
        try {
            return await this.contract.timeLimit();
        } catch (error) {
            console.error('Error getting time limit:', error);
            throw error;
        }
    };
}

export default PuushdotLiquidityV103Instance;
