// FILE: CoinGridt.tsx
import './CoinGrid.css';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CoinGeneralInfo, CoinMetadataForS3 } from '../../providers/CoinListProvider';
import { displayDate, displayName, getCroImage, getPercentageSold } from '../../utils/generalUtils';


interface CoinGridProps {
    coinList: CoinGeneralInfo[];
}

const CoinGrid: React.FC<CoinGridProps> = ({ coinList }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20; // Adjust the number of items per page
    const totalPages = Math.ceil(coinList.length / itemsPerPage);

    // Animation states
    const [prevFirstCoin, setPrevFirstCoin] = useState<string | null>(null);
    const [firstLoad, setFirstLoad] = useState(true);
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (coinList.length > 0) {
            if (firstLoad) {
                setFirstLoad(false);
                setPrevFirstCoin(coinList[0].coinAddress);
            } else if (prevFirstCoin !== coinList[0].coinAddress) {
                setAnimate(true);
                setTimeout(() => setAnimate(false), 1000); // Animation duration
            }
            setPrevFirstCoin(coinList[0].coinAddress);
        }
    }, [coinList]);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentItems = coinList.slice(startIndex, startIndex + itemsPerPage);

    const handleFirstPage = () => setCurrentPage(1);
    const handlePrevPage = () => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
    const handleNextPage = () => setCurrentPage(currentPage < totalPages ? currentPage + 1 : totalPages);
    const handleLastPage = () => setCurrentPage(totalPages);

    return (
        <div>
            <div className="pagination-controls">
                <button onClick={handleFirstPage} disabled={currentPage === 1}>{'<<'}</button>
                <button onClick={handlePrevPage} disabled={currentPage === 1}>{'<'}</button>
                <span>{currentPage} of {totalPages}</span>
                <button onClick={handleNextPage} disabled={currentPage === totalPages}>{'>'}</button>
                <button onClick={handleLastPage} disabled={currentPage === totalPages}>{'>>'}</button>
            </div>
            <div className="CoinGrid-container">
                {currentItems.map((coin, index) => (
                    <CoinCard key={coin.coinAddress} coin={coin} index={index} animate={animate} />
                ))}
            </div>
            <div className="pagination-controls">
                <button onClick={handleFirstPage} disabled={currentPage === 1}>{'<<'}</button>
                <button onClick={handlePrevPage} disabled={currentPage === 1}>{'<'}</button>
                <span>{currentPage} of {totalPages}</span>
                <button onClick={handleNextPage} disabled={currentPage === totalPages}>{'>'}</button>
                <button onClick={handleLastPage} disabled={currentPage === totalPages}>{'>>'}</button>
            </div>
        </div>
    );
};

type CoinCardProps = {
    coin: CoinGeneralInfo;
    index: number;
    animate: boolean;
};

const CoinCard: React.FC<CoinCardProps> = ({ coin, animate, index }) => {

    const defaultMetadata: CoinMetadataForS3 = {
        name: 'Loading...',
        ticker: 'Loading..',
        description: 'Loading...',
        imageURL: '',
        twitter: 'Loading...',
        telegram: 'Loading...',
        website: 'Loading...',
    };

    const [coinMetadata, setCoinMetadata] = useState<CoinMetadataForS3>(defaultMetadata);
    const [imageLoaded, setImageLoaded] = useState(false);
    const navigate = useNavigate();

    const navToCoin = (coinAddress: string) => {
        navigate(`/coin/${coinAddress}`);
    };

    useEffect(() => {
        if (coin.metadata) {
            setCoinMetadata({ ...coin.metadata });
        } else {
        }
    }, [coin.metadata]);

    const openAddressExplorer = (event: React.MouseEvent<HTMLDivElement>, address: string) => {
        event.stopPropagation();
        window.open(`https://cronoscan.com/address/${address}`, '_blank');
    }

    const handleImageError = () => {
        setImageLoaded(true); // Mark as loaded to hide the placeholder
        setCoinMetadata({ ...coinMetadata, imageURL: 'https://placehold.co/400x400/000/000' })
    };


    return (
        <div className={`CoinGrid-item clicky ${index === 0 && animate ? 'animate' : ''}`} onClick={() => navToCoin(coin.coinAddress)}>
            <div className="CoinGrid-image-container">
                {!imageLoaded && <div className="CoinGrid-image-placeholder"></div>}
                <img
                    className="CoinGrid-image"
                    src={coinMetadata.imageURL || 'https://placehold.co/400x400/000/000'} // Provide a fallback image URL
                    alt=""
                    loading="lazy"
                    onLoad={() => setImageLoaded(true)}
                    onError={handleImageError} // Handle image load errors
                />
            </div>
            <div className="CoinGrid-info">
                <div
                    className='CoinGrid-createdBy'
                    onClick={(event) => openAddressExplorer(event, coin.creatorAddress)}
                >
                    {getCroImage(coin.chainId)}Created by {displayName(coin.creatorAddress)}</div>
                <div className='CoinGrid-updatedAt'>Updated {displayDate(coin.lastUpdated)}</div>
                <div className='CoinGrid-coinsForSale'>{getPercentageSold(coin.fundsCoinBalance, coin.puushdotfunVersion)}% SOLD</div>
                <div className='CoinGrid-description'>
                    <span className='bolder'>
                        {coinMetadata.name} ({coinMetadata.ticker}){' - '}
                    </span>
                    {coinMetadata.description}
                </div>
            </div>
        </div>
    );
}

export default CoinGrid;