import { useEffect, useState } from 'react';
import puushdotApi from '../../requests/puushdotfunApi';
import './TradingRewards.css';

type WeeklyPoints = {
    totalPoints: number;
    buyPoints: number;
    sellPoints: number;
    graduationHolderPoints: number;
    graduationCreatorPoints: number;
}

const TradingRewards = () => {

    const [weeklyPoints, setWeeklyPoints] = useState<Map<string, Map<string, WeeklyPoints>>>(new Map<string, Map<string, WeeklyPoints>>());

    // init weekly points
    useEffect(() => {
        const fetchWeeklyPoints = async () => {
            try {
                const points = await puushdotApi.getWeeklyPoints();
                setWeeklyPoints(points);
                console.log('points', points);
            } catch (error) {
                console.log(error);
            }
        };
        fetchWeeklyPoints();
    }, []);

    return (
        <div className="TradingRewards">
            <h1>Trading Rewards</h1>
            <h3>COMING S👀N</h3>
        </div>
    );
};

export default TradingRewards