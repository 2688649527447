// FILE: src/providers/WebSocketProvider.tsx
import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { io, Socket } from 'socket.io-client';
import config from '../config';

interface WebSocketProviderProps {
    children: ReactNode;
}

export interface WebSocketContextType {
    socket: Socket | null;
    joinCoinRoom: (coinAddress: string) => void;
    leaveCoinRoom: (coinAddress: string) => void;
}

const WebSocketContext = createContext<WebSocketContextType | null>(null);

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
    const [socket, setSocket] = useState<Socket | null>(null);

    useEffect(() => {
        const newSocket = io(config.BASE_WS_URL, {
            reconnectionAttempts: 5,
            reconnectionDelay: 1000,
        });
        setSocket(newSocket);

        newSocket.on('connect', () => {
            console.log('Connected to WebSocket server');
        });

        newSocket.on('disconnect', (reason) => {
            console.log(`Disconnected from WebSocket server: ${reason}`);
            if (reason === 'io server disconnect') {
                // The disconnection was initiated by the server, you need to reconnect manually
                newSocket.connect();
            }
        });

        return () => {
            newSocket.close();
        };
    }, []);

    const joinCoinRoom = (coinAddress: string) => {
        if (socket) {
            socket.emit('joinCoinRoom', coinAddress);
        }
    };

    const leaveCoinRoom = (coinAddress: string) => {
        if (socket) {
            socket.emit('leaveCoinRoom', coinAddress);
        }
    };

    return (
        <WebSocketContext.Provider value={{ socket, joinCoinRoom, leaveCoinRoom }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => {
    return useContext(WebSocketContext);
};
