import { useState, useEffect } from 'react';

const useLoadingWithDots = (loading: boolean) => {
    const [dots, setDots] = useState('');

    useEffect(() => {
        if (loading) {
            const interval = setInterval(() => {
                setDots(prevDots => (prevDots.length < 3 ? prevDots + '.' : ''));
            }, 450);
            return () => clearInterval(interval);
        } else {
            setDots('');
        }
    }, [loading]);

    return `Loading${dots}`;
};

export default useLoadingWithDots;
