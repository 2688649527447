import './Footer.css';

const Footer: React.FC = () => {
    return (
        <div className='Footer text-white'>
            <div className='Footer-disclaimer'>
                By accessing and using this website, you agree to participate at your own risk. All interactions with this website are at your own discretion and responsibility. We do not assume any liability for any losses or damages incurred as a result of your use of our website. By continuing to use our site, you acknowledge and agree to these terms. If you do not agree with these terms, please do not use this website.
            </div>
            <div className='Footer-copyright'>
                © 2024 puushdotfun
            </div>
        </div>
    )
};

export default Footer;