import React from 'react';
import './WarningModal.css';

interface WarningModalProps {
    url: string;
    onConfirm: () => void;
    onCancel: () => void;
}

const WarningModal: React.FC<WarningModalProps> = ({ url, onConfirm, onCancel }) => {
    return (
        <div className="warning-modal">
            <div className="warning-modal-content">
                <h2>Proceed with Caution</h2>
                <p className='text-white'>⚠️ You are about to visit:</p>
                <p className='small-text long-text'>{url}</p>
                <p className='text-orange small-text'>This URL has not been verified. Are you sure you want to proceed?</p>
                <p className='disclaimer'>
                    Disclaimer: By clicking "Yes", you acknowledge that this link is not verified, and you proceed at your own risk.
                    Puush.fun will not be liable for any damages that may arise from visiting this link.
                </p>
                <button className='yes' onClick={onConfirm}>Yes</button>
                <button className='no' onClick={onCancel}>No</button>
            </div>
        </div>
    );
};

export default WarningModal;
