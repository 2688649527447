import axios from "axios";
import config from "../config";
import { CoinMetadataPayload } from "../nav/StartNewCoin/StartNewCoin";

// load config variables from ENV
const BASE_API_URL = config.BASE_API_URL;

const axiosInstance = axios.create({
    baseURL: BASE_API_URL,
    withCredentials: true,
    // ... other axios settings
});

const axiosInstanceNoCredentials = axios.create({
    baseURL: BASE_API_URL,
    // ... other axios settings
});

const puushdotApi = {
    // Authenticate user with signed message
    authenticateUser: async (userAddress: string, message: string, signature: string) => {
        console.log(`Authenticating user with address: ${userAddress}`)
        try {
            const response = await axiosInstance.post('/api/v1/auth/authenticate', {
                userAddress,
                message,
                signature
            });

            return response.data;
        } catch (error) {
            throw new Error('Authentication failed');
        }
    },

    submitMetadata: async (metadata: FormData) => {
        // console.log(`Submitting metadata: ${metadata}`)
        try {
            const response = await axiosInstance.post('/api/v1/user/submit_metadata', metadata, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('response', response)
            return response.data;
        } catch (error) {
            console.log(error)
            throw new Error('Metadata submission failed');
        }
    },

    // Check if user is still logged in
    checkUserIsLoggedIn: async () => {
        try {
            const response = await axiosInstance.get('/api/v1/user/is_logged_in');
            return response.data;
        } catch (error) {
            throw new Error('Authentication failed');
        }
    },

    getWeeklyPoints: async () => {
        try {
            const response = await axiosInstanceNoCredentials.get('/api/v1/public/weeklyPoints');
            return response.data;
        } catch (error) {
            console.log(error)
            throw new Error('Error fetching weekly points');
        }
    },
}

export default puushdotApi;
