import axios from 'axios';
import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';

declare module 'axios' {
    interface AxiosRequestConfig {
        jar?: CookieJar;
    }
}

const jar = new CookieJar();

const axiosInstance = wrapper(axios.create({
    baseURL: 'https://cronos.org/explorer',
    jar,
}));

export type TokenListResponse = {
    balance: string;
    contractAddress: string;
    decimals: string;
    name: string;
    symbol: string;
    type: string;
};

const cronosApi = {
    getTokenList: async (address: string): Promise<TokenListResponse[]> => {
        try {
            const response = await axiosInstance.get(`api?module=account&action=tokenlist&address=${address}`);
            return response.data.result;
        } catch (error) {
            console.error('Failed to get token list', error);
            throw new Error('Failed to get token list');
        }
    },
}

export default cronosApi;

