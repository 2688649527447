import { CoinGeneralInfo } from "../../providers/CoinListProvider";
import { getAmountToBuy, handleInputChangeWithMinMax, toEth, toWei, getReturn, formatDecimal, getAmountToBuy2, getReturnV102 } from "../../utils/priceUtils";
import React, { useEffect, useState } from 'react';
import CustomCoin from "../../components/CustomCoin/CustomCoin";
import useWallet from '../../hooks/useWallet';
import { Decimal } from 'decimal.js';
import config from "../../config";
import PuushdotInstance from "../../web3/puushdotInstance";
import PuushdotV101Instance from "../../web3/puushdotV101Instance";
import PuushdotV102Instance from "../../web3/puushdotV102Instance";
import PuushdotV103Instance from "../../web3/puushdotV103Instance";
import { showErrorToast } from "../../components/ToastService/ToastService";

type BuySellSectionProps = {
    coin: CoinGeneralInfo;
};

const BuySellSection: React.FC<BuySellSectionProps> = ({ coin }) => {

    const coinImageURL = coin.metadata?.imageURL;
    const coinAddress = coin.coinAddress;
    const fundsCoinBalance = coin.fundsCoinBalance;
    const fundsEthBalance = coin.fundsEthBalance;

    const {
        account,
        puushdotInstance,
        puushdotV101Instance,
        puushdotV102Instance,
        puushdotV103Instance,
        balance,
        coinBalance,
        getCoinBalance,
        getCoinAllowance,
        coinAllowance,
        approveCoin,
        refreshBalances,
        chainId,
    } = useWallet();
    const [view, setView] = useState<'buy' | 'sell'>('buy');
    const [slip, setSlip] = useState<number>(0.5);
    const [amount, setAmount] = useState<bigint>(BigInt(0));
    const [amountDisplayed, setAmountDisplayed] = useState<string>('0');
    const [loading, setLoading] = useState<boolean>(false);

    const slipValues = [0.1, 0.5, 1, 2, 5, 10, 20, 50, 100];
    const isDanger = slip > 5;

    const croInputPercentValues = [0.25, 0.5, 0.75, 0.99];
    const coinInputPercentValues = [0.25, 0.5, 0.75, 1];

    const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleInputChangeWithMinMax(e, setAmount, setAmountDisplayed, new Decimal(0), getMaxAmount(), getDecimals());
    };

    const getMaxAmount = (): Decimal => {
        return new Decimal(view === 'buy' ? toEth(balance, 18) : toEth(coinBalance, 18));
    };

    const getCroImage = () => {
        return coin.chainId !== '388' ? 'https://s3.coinmarketcap.com/static-gravity/image/84e1ec1257dd4a6da6ee5584338a2460.jpeg' : 'https://puushdabutton-web-images.s3.amazonaws.com/Coin_namezkCRO.png';
    }

    const getSpendURL = () => {
        return view === 'buy' ? getCroImage() : coinImageURL;
    }

    const getReceiveURL = () => {
        return view === 'buy' ? coinImageURL : getCroImage();
    };

    const croLogo = <CustomCoin url={getCroImage()} size={15} />
    const coinLogo = <CustomCoin url={coinImageURL} size={15} />

    // 1 billion in wei - fundsCoinBalance (already in wei)
    const soldWei = () => {
        return (BigInt(toWei('1000000000')) - BigInt(fundsCoinBalance)).toString();
    };

    const amountWei = (): string => {
        return amount.toString();
    };

    const coinGettingWei = () => {
        switch (coin.puushdotfunVersion) {
            case 'v1':
            case config.PUUSHDOTFUN_CONTRACT_ADDRESS.toLowerCase():
            case config.PUUSHDOTFUN_V101_CONTRACT_ADDRESS.toLowerCase():
                return (getAmountToBuy(soldWei(), amountWei()))
            case config.PUUSHDOTFUN_V102_CONTRACT_ADDRESS.toLowerCase():
            case config.PUUSHDOTFUN_V103_CONTRACT_ADDRESS_CRONOSZKEVM.toLowerCase():
            default:
                return (getAmountToBuy2(soldWei(), amountWei()))
        }
    }

    const coinGettingMinWei = () => {
        return (BigInt(coinGettingWei()) - BigInt(coinGettingWei()) * BigInt(slip * 100) / BigInt(10000)).toString();
    };

    const croReturnWei = () => {
        switch (coin.puushdotfunVersion) {
            case config.PUUSHDOTFUN_V101_CONTRACT_ADDRESS.toLowerCase():
            case config.PUUSHDOTFUN_CONTRACT_ADDRESS.toLowerCase():
            case 'v1':
                return getReturn(soldWei(), amountWei(), fundsEthBalance, fundsCoinBalance);
            default:
            case config.PUUSHDOTFUN_V103_CONTRACT_ADDRESS_CRONOSZKEVM.toLowerCase():
            case config.PUUSHDOTFUN_V102_CONTRACT_ADDRESS.toLowerCase():
                return getReturnV102(soldWei(), amountWei(), fundsEthBalance, fundsCoinBalance)
        }
    }

    const croReturnMinWei = () => {
        return (BigInt(croReturnWei()) - BigInt(croReturnWei()) * BigInt(slip * 100) / BigInt(10000)).toString();
    };

    const getDecimals = (): number => {
        switch (coin.puushdotfunVersion) {
            case 'v1':
            case config.PUUSHDOTFUN_CONTRACT_ADDRESS.toLowerCase():
                return 18;
            case config.PUUSHDOTFUN_V101_CONTRACT_ADDRESS.toLowerCase():
            case config.PUUSHDOTFUN_V102_CONTRACT_ADDRESS.toLowerCase():
            default:
                return 13;
        };
    };

    const handlePercentClick = (percent: number) => {
        const maxAmount = getMaxAmount();
        let newValue: string;
        if (percent === 1) {
            newValue = maxAmount.toFixed(18);
        } else {
            newValue = maxAmount.mul(percent).toFixed(13);
        }
        // const newValue = maxAmount.mul(percent).toFixed(13);
        const fakeEvent = { target: { value: newValue } } as React.ChangeEvent<HTMLInputElement>;
        handleAmountChange(fakeEvent);
    };

    const getPuushdotInstance = () => {
        switch (coin.puushdotfunVersion) {
            case 'v1':
            case config.PUUSHDOTFUN_CONTRACT_ADDRESS.toLowerCase():
                return puushdotInstance;
            case config.PUUSHDOTFUN_V101_CONTRACT_ADDRESS.toLowerCase():
                return puushdotV101Instance;
            case config.PUUSHDOTFUN_V102_CONTRACT_ADDRESS.toLowerCase():
                return puushdotV102Instance;
            case config.PUUSHDOTFUN_V103_CONTRACT_ADDRESS_CRONOSZKEVM.toLowerCase():
            case config.PUUSHDOTFUN_V103_CONTRACT_ADDRESS.toLowerCase():
                return puushdotV103Instance;
            default:
                return null;
        };
    };

    const buy = async (coinAddress: string, amountWei: string, minAmountWei: string, soldSupplyWei: string, instance: PuushdotInstance | PuushdotV101Instance | PuushdotV102Instance | PuushdotV103Instance | null) => {
        if (!instance) {
            console.error('PuushdotInstance is not initialized');
            return;
        }
        const tx = await instance.buyCoins(
            coinAddress,
            amountWei,
            minAmountWei,
            soldSupplyWei,
        )

        console.log('tx:', tx)
    }

    const sell = async (coinAddress: string, amountWei: string, minEthReturnWei: string, instance: PuushdotInstance | PuushdotV101Instance | PuushdotV102Instance | PuushdotV103Instance | null) => {
        if (!instance) {
            console.error('PuushdotInstance is not initialized');
            return;
        }
        const tx = await instance.sellCoins(
            coinAddress,
            amountWei,
            minEthReturnWei,
        )
    };

    const approve = async () => {
        const max = toWei('100000000000');
        await approveCoin(coinAddress, coin.fundsAddress, max);

    };

    const buyWithLoad = async () => {
        // Error checks
        // Check if user is connected
        if (!account || !chainId) {
            showErrorToast('Please connect your wallet.');
            return;
        }
        // Check chainId of coin vs chainId of the wallet
        if (coin.chainId !== chainId.toString()) {
            showErrorToast('Please switch to the correct network.');
            return;
        }


        withLoading(() => buy(coinAddress, coinGettingWei(), coinGettingMinWei(), soldWei(), getPuushdotInstance()));
    };

    const sellWithLoad = async () => {
        withLoading(() => sell(coinAddress, amountWei(), croReturnMinWei(), getPuushdotInstance()));
    };

    const approveWithLoad = async () => {
        withLoading(() => approve());
    };

    const withLoading = async (func: () => Promise<void>) => {
        if (loading) return;
        setLoading(true);
        try {
            await func();
            await refreshBalances(coin.coinAddress, coin.fundsAddress)
        } catch (ex) {
            console.error('Error:', ex);
        }
        setLoading(false);
    };

    const hasEnoughAllowance = () => {
        return BigInt(coinAllowance) >= BigInt(amountWei());
    };

    useEffect(() => {
        if (account) {
            getCoinBalance(coinAddress);
            getCoinAllowance(coinAddress, coin.fundsAddress);
        }
    }, [account]);

    return (
        <div className='BuySellSection'>
            <div className='full-row' style={{ gap: '10px' }}>
                <div
                    className={`BuySellSection-viewbutton ${view === 'buy' ? view : 'neutral'}`}
                    onClick={() => setView('buy')}
                >
                    Buying
                </div>
                <div
                    className={`BuySellSection-viewbutton ${view === 'buy' ? 'neutral' : view}`}
                    onClick={() => setView('sell')}
                >
                    Selling
                </div>

            </div>
            <div>
                <input type='range' min={0} max={slipValues.length - 1} value={slipValues.indexOf(slip)} onChange={(e) => setSlip(slipValues[parseInt(e.target.value)])} />
                <div className={isDanger ? 'text-danger' : 'text-white'}>
                    {/**Show warning if slip is bigger than 5 */}
                    {slip}% slippage {isDanger ? '⚠️ PROS ONLY!' : ''}
                </div>
            </div>
            <div className='full-row-between text-white'>
                <div className="row">Bal: {toEth(balance, 2)}{croLogo}</div>
                <div className="row">Bal: {toEth(coinBalance, 2)}{coinLogo}</div>
            </div>
            <div className="full-row-left-gap" style={{ marginBottom: '-10px' }}>

                {
                    view === 'buy' ?
                        croInputPercentValues.map((percent) => {
                            return (
                                <div
                                    className='BuySellSection-percentbutton'
                                    onClick={() => handlePercentClick(percent)}
                                >
                                    {percent * 100}%
                                </div>
                            )
                        })
                        :
                        coinInputPercentValues.map((percent) => {
                            return (
                                <div
                                    className='BuySellSection-percentbutton'
                                    onClick={() => handlePercentClick(percent)}
                                >
                                    {percent * 100}%
                                </div>
                            )
                        })
                }
            </div>

            <div className='BuySellSection-input-container'>
                <input
                    type='text'
                    placeholder='0.0'
                    value={amountDisplayed}
                    onChange={handleAmountChange}
                    className={`BuySellSection-input ${loading ? 'disabled' : ''}`}
                    disabled={loading}
                />
                <div className='BuySellSection-coin-image'>
                    <CustomCoin url={getSpendURL()} size={30} />
                </div>
                <div className='BuySellSection-tradeinfo text-white'>
                    {
                        view === 'buy' ?
                            <>
                                <div className='full-row left gapped center'>
                                    ≈ {toEth(coinGettingWei(), 2)} <CustomCoin url={getReceiveURL()} size={15} />
                                </div>
                                <div className='full-row left gapped center'>
                                    Minumum ≈ {toEth(coinGettingMinWei(), 2)} <CustomCoin url={getReceiveURL()} size={15} />
                                </div>
                            </>
                            :
                            <>
                                <div className='full-row left gapped center'>
                                    ≈ {toEth(croReturnWei(), 2)} <CustomCoin url={getReceiveURL()} size={15} />
                                </div>
                                <div className='full-row left gapped center'>
                                    Minumum ≈ {toEth(croReturnMinWei(), 2)} <CustomCoin url={getReceiveURL()} size={15} />
                                </div>
                            </>
                    }
                    <div className='full-row left gapped center'>
                        Fee = 1% <CustomCoin url={getCroImage()} size={15} />
                    </div>
                </div>
            </div>
            <div
                className={`BuySellSection-viewbutton ${!loading ? view : 'disabled'}`}
                onClick={
                    view === 'buy' ? () => buyWithLoad() :
                        hasEnoughAllowance() ? () => sellWithLoad() :
                            () => approveWithLoad()

                }
            >
                {
                    view === 'buy' ? 'Buy' :
                        hasEnoughAllowance() ? 'Sell' :
                            'Approve'
                }
            </div>
        </div>
    );
};

export default BuySellSection;