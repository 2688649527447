// FILE: src/nav/Home/Home.tsx
import { CoinGeneralInfo, useCoinList } from '../../providers/CoinListProvider';
import { useEffect, useState } from 'react';
import BiggestPuusher from '../../components/BiggestPuusher/BiggestPuusher';
import CoinGridFiltering from '../../components/CoinGridFiltering/CoinGridFiltering';
import { toEth } from '../../utils/priceUtils';
import CoinGrid from '../../components/CoinGrid/CoinGrid';
import Spotlight from '../../components/Spotlight/Spotlight';
import useWallet from '../../hooks/useWallet';
import cronosApi from '../../requests/cronosApi';

const Home = () => {
    const { account } = useWallet();
    const { coinList } = useCoinList();
    const [filteredCoinList, setFilteredCoinList] = useState<CoinGeneralInfo[]>([]);
    const [biggestPuusher, setBiggestPuusher] = useState<CoinGeneralInfo | null>(null);
    const [sortBy, setSortBy] = useState<string>('lastUpdated');
    const [chainSelect, setChainSelect] = useState<string>('all');
    const [showGraduated, setShowGraduated] = useState<boolean>(false);
    const [showNSFW, setShowNSFW] = useState<boolean>(true);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [showOwnedOnly, setShowOwnedOnly] = useState<boolean>(false);
    const [ownedCoinList, setOwnedCoinList] = useState<CoinGeneralInfo[]>([]);
    const [ownedOnlyActive, setOwnedOnlyActive] = useState<boolean>(false);

    useEffect(() => {
        if (filteredCoinList.length > 0) {
            const nonGraduatedCoins = filteredCoinList.filter(coin => !coin.hasGraduated);
            const nonGraduatedCoinsWithoutNSFW = nonGraduatedCoins.filter(coin => !coin.metadata?.nsfwScore || coin.metadata.nsfwScore < 91);
            if (nonGraduatedCoinsWithoutNSFW.length > 0) {
                const biggestPuusher = nonGraduatedCoinsWithoutNSFW.reduce((prev, current) => {
                    return (BigInt(prev.fundsCoinBalance) < BigInt(current.fundsCoinBalance)) ? prev : current;
                });
                setBiggestPuusher(biggestPuusher);
            } else {
                setBiggestPuusher(null); // No non-graduated coins found
            }
        }
    }, [filteredCoinList]);

    // Get all owned coins
    useEffect(() => {
        // Don't show owned coins in dev, request to cronos API isnt accepted from localhost
        if (!account) return;
        // Get all owned tokens from API
        cronosApi.getTokenList(account).then(allOwnedTokens => {
            const ownedCoins = coinList.filter(coin => allOwnedTokens.find(token => token.contractAddress === coin.coinAddress.toLowerCase()));
            setOwnedCoinList(ownedCoins);

        })
        setOwnedOnlyActive(true);
    }, [account, coinList]);

    useEffect(() => {
        let updatedList = [...coinList];

        if (searchQuery) {
            updatedList = updatedList.filter(coin =>
            (coin.metadata?.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                coin.metadata?.ticker.toLowerCase().includes(searchQuery.toLowerCase()))
            );
        }

        if (chainSelect !== 'all') {
            updatedList = updatedList.filter(coin => coin.chainId === chainSelect);
        }

        if (sortBy === 'dateCreated') {
            updatedList = updatedList.sort((a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime());
        } else if (sortBy === 'lastUpdated') {
            updatedList = updatedList.sort((a, b) => new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime());
        } else if (sortBy === 'fundsCoinBalance') {
            updatedList = updatedList.sort((a, b) => Number(toEth(a.fundsCoinBalance)) - Number(toEth(b.fundsCoinBalance)));
        }

        if (!showGraduated) {
            updatedList = updatedList.filter(coin => !coin.hasGraduated);
        }

        if (!showNSFW) {
            // Remove coins that have a score over 50 for NSFW, if the metadata doesnt have an nsfwScore, it is not considered NSFW
            updatedList = updatedList.filter(coin => !coin.metadata?.nsfwScore || coin.metadata.nsfwScore < 75);
        }

        if (showOwnedOnly) {
            updatedList = updatedList.filter(coin => ownedCoinList.includes(coin));
        }

        setFilteredCoinList(updatedList);

    }, [coinList, sortBy, showGraduated, showNSFW, searchQuery, ownedCoinList, showOwnedOnly, chainSelect]);

    return (
        <div>
            <div className='full-row wrapped gapped'>
                {biggestPuusher && <BiggestPuusher coin={biggestPuusher} />}
                <Spotlight />
                {/* <PuushToWin /> */}
            </div>
            <CoinGridFiltering
                onSortChange={setSortBy}
                onChainChange={setChainSelect}
                onShowGraduatedToggle={setShowGraduated}
                onShowNSFWToggle={setShowNSFW}
                onSearchChange={setSearchQuery}
                onShowOwnedOnlyToggle={setShowOwnedOnly}
                ownedOnlyActive={ownedOnlyActive}
            />
            <CoinGrid coinList={filteredCoinList} />
        </div>
    );
};

export default Home;
