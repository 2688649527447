import axios from "axios";

export type TokenHolderData = {
    holder_count: string,
    holders: Array<{ // Top 10 holders
        address: string,
        tag: string,
        is_contract: number,
        balance: string,
        percent: string,
        is_locked: number
    }>,
};

export type CoinLiquidityV101Data = {
    coinText: string;
    routerId: string;
    routerAddress: string;
    timeSetInSeconds: number;
};

export type GetCoinInfoResponse = {
    holderInfo: TokenHolderData;
    liquidityV101Info: CoinLiquidityV101Data;
};

export type GetPuushdotWinApiDataResponse = {
    gameId: string;
    jackpot: string;
    lastJackpot: string;
    lastDepositTime: string;
    lastDepositId: string;
    timeLeft: string;
    lastDepositor: string;
    lastJackpotWinner: string;
    paused: boolean;
};

export type PlayerDepositData = {
    txHash: string;
    timestamp: string;
    gameId: string;
    depositId: string;
};

export type GetPuushdotWinPlayerDataResponse = Array<PlayerDepositData>

const axiosInstance = axios.create({
    baseURL: 'https://api.boomersquad.io',
});

const boomerApi = {
    // Get spotlight coin address
    getSpotlightCoin: async (chainId: number): Promise<{ coinAddress: string, lastTimestamp: number }> => {
        try {
            const response = await axiosInstance.get(`/puush/spotlight_coin/${chainId}`);
            return response.data;
        } catch (error) {
            throw new Error('Failed to get spotlight coin');
        }
    },

    // Get global info for platform
    getGlobalInfo: async (): Promise<{ PUUSHBurned: string, CROUSD: number }> => {
        try {
            const response = await axiosInstance.get('/puush/global_info');
            return response.data;
        } catch (error) {
            throw new Error('Failed to get global info');
        }
    },

    // Get coin info for a given coin address
    getCoinInfo: async (coinAddress: string): Promise<GetCoinInfoResponse | undefined> => {
        try {
            const response = await axiosInstance.get(`/puush/coin_info/${coinAddress}/25`); // only support chainId 25 for now
            return response.data;
        } catch (error) {
            return undefined;
        }
    },

    getPuushdotWinData: async (): Promise<GetPuushdotWinApiDataResponse> => {
        try {
            const response = await axiosInstance.get('/puush/puushdotwin');
            return response.data;
        } catch (error) {
            throw new Error('Failed to get puushdotwin data');
        }
    },

    getPuushdotWinPlayerData: async (address: string): Promise<GetPuushdotWinPlayerDataResponse> => {
        try {
            const response = await axiosInstance.get(`/puush/puushdotwin/${address}`);
            console.log(response.data);
            return response.data;
        } catch (error) {
            throw new Error('Failed to get puushdotwin player data');
        }
    },
};

export default boomerApi;
