// src/components/ToastService/ToastService.tsx
import { toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ToastService.css';  // Import the custom CSS file

const defaultOptions: ToastOptions = {
    position: "top-right",
    autoClose: 5000, // Close after 5 seconds
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
    className: 'toastify-custom',  // Apply the custom class to all toasts
};

export const showErrorToast = (message: string, options: ToastOptions = {}) => {
    toast.error(message, { ...defaultOptions, ...options });
};

// Optionally, you can create a success toast function similarly
export const showSuccessToast = (message: string, options: ToastOptions = {}) => {
    toast.success(message, { ...defaultOptions, ...options });
};

export default function ToastService() {
    return null; // This component doesn't render anything, it's just for utility functions
}
