import axios from "axios";
import config from "../config";

const axiosInstance = axios.create({
    baseURL: config.CHAT_BASE_HTTP_URL,
    withCredentials: true,
});

const chatApi = {
    // Post a message with an optional image
    postMessage: async (messageData: FormData) => {
        try {
            const response = await axiosInstance.post('/api/v1/chat/postMessage', messageData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data;
        } catch (error: any) {
            if (error.response) {
                if (error.response.status === 429) {
                    return error.response.status;
                };
            }
        }
    },

    login: async (userAddress: string, message: string, signature: string) => {
        console.log(`Login with address: ${userAddress}`)
        try {
            const response = await axiosInstance.post('/api/v1/log/in', {
                userAddress,
                message,
                signature
            });

            return response.data;
        } catch (error) {
            throw new Error('Authentication failed');
        }
    },

    logout: async () => {
        try {
            const response = await axiosInstance.get('/api/v1/log/out');
            console.log('Response from server:', response);
            return response;
        } catch (error: any) {
            console.log('Error:', error);
        }
    },

    isLoggedIn: async (userAddress: string) => {
        try {
            const response = await axiosInstance.post('/api/v1/log/isLoggedIn', { userAddress });
            console.log('Response from server:', response);
            return response;
        } catch (error: any) {
            console.log('Error:', error);
        }
    },
};

export default chatApi;
