import config from "../config";

// SPOTLIGHTS
export const getSpotlightContractAddress = (chainId: number): string => {
    if (chainId === 25) {
        return config.SPOTLIGHT_CONTRACT_ADDRESS;
    } else if (chainId === 388) {
        return config.PUUSHDOTSPOTLIGHT_CRONOSZKEVM;
    } else {
        return config.SPOTLIGHT_CONTRACT_ADDRESS;
    };
};

// PUUSH DOT FUNS
export const getPuushdotFunContractAddress = (chainId: number): string => {
    if (chainId === 25) {
        return config.PUUSHDOTFUN_CONTRACT_ADDRESS;
    } else if (chainId === 388) {
        throw new Error('PDF contract address not available on Cronos zkEVM');
    } else {
        throw new Error('Invalid chain ID');
    }
};

export const getPuushdotFunV101ContractAddress = (chainId: number): string => {
    if (chainId === 25) {
        return config.PUUSHDOTFUN_V101_CONTRACT_ADDRESS;
    } else if (chainId === 388) {
        throw new Error('PDF V101 contract address not available on Cronos zkEVM');
    } else {
        throw new Error('Invalid chain ID');
    }
};

export const getPuushdotFunV102ContractAddress = (chainId: number): string => {
    if (chainId === 25) {
        return config.PUUSHDOTFUN_V102_CONTRACT_ADDRESS;
    } else if (chainId === 388) {
        throw new Error('PDF V102 contract address not available on Cronos zkEVM');
    } else {
        throw new Error('Invalid chain ID');
    }
};

export const getPuushdotFunV103ContractAddress = (chainId: number): string => {
    if (chainId === 25) {
        return config.PUUSHDOTFUN_V103_CONTRACT_ADDRESS;
    } else if (chainId === 388) {
        return config.PUUSHDOTFUN_V103_CONTRACT_ADDRESS_CRONOSZKEVM;
    } else {
        throw new Error('Invalid chain ID');
    }
};

// PUUSH DOT LIQUIDITYS
export const getPuushdotLiquidityV101ContractAddress = (chainId: number): string => {
    if (chainId === 25) {
        return config.PUUSHDOTLIQUIDITYV101_CONTRACT_ADDRESS;
    } else if (chainId === 388) {
        throw new Error('PDF V101 contract address not available on Cronos zkEVM');
    } else {
        throw new Error('Invalid chain ID');
    }
};

export const getPuushdotLiquidityV103ContractAddress = (chainId: number): string => {
    if (chainId === 25) {
        return config.PUUSHDOTLIQUIDITYV103_CONTRACT_ADDRESS;
    } else if (chainId === 388) {
        return config.PUUSHDOTLIQUIDITYV103_CONTRACT_ADDRESS_CRONOSZKEVM;
    } else {
        throw new Error('Invalid chain ID');
    }
};