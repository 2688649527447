import React, { useState } from 'react';
import './TxLogList.css';
import { TransactionLog } from '../../providers/CoinListProvider';
import { displayName, openAddressExplorer, openTxExplorer } from '../../utils/generalUtils';
import { formatDistanceToNow } from 'date-fns';
import { toEth } from '../../utils/priceUtils';

type TxLogListProps = {
    logs: TransactionLog[];
};

const TxLogList: React.FC<TxLogListProps> = ({ logs }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20; // Adjust the number of items per page
    const totalPages = Math.ceil(logs.length / itemsPerPage);

    // Reverse the logs array
    const reversedLogs = [...logs].reverse();

    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentItems = reversedLogs.slice(startIndex, startIndex + itemsPerPage);

    const handleFirstPage = () => setCurrentPage(1);
    const handlePrevPage = () => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
    const handleNextPage = () => setCurrentPage(currentPage < totalPages ? currentPage + 1 : totalPages);
    const handleLastPage = () => setCurrentPage(totalPages);

    return (
        <div className='full-row-column text-white'>
            <div className="pagination-controls">
                <button onClick={handleFirstPage} disabled={currentPage === 1}>{'<<'}</button>
                <button onClick={handlePrevPage} disabled={currentPage === 1}>{'<'}</button>
                <span>{currentPage} of {totalPages}</span>
                <button onClick={handleNextPage} disabled={currentPage === totalPages}>{'>'}</button>
                <button onClick={handleLastPage} disabled={currentPage === totalPages}>{'>>'}</button>
            </div>
            <div className='TxLogList'>
                <div className='TxLog TxLog-header'>
                    <div>type</div>
                    <div>cro</div>
                    <div>coin</div>
                    <div>price</div>
                    <div>time</div>
                    <div>user</div>
                    <div>tx</div>
                </div>
                {currentItems.map(log => (
                    <TxLog key={log.txHash} log={log} />
                ))}
            </div>
        </div>
    );

};

type TxLogProps = {
    log: TransactionLog;
};

const customFormatDistanceToNow = (date: Date, options?: { addSuffix?: boolean }) => {
    const formatted = formatDistanceToNow(date, options);
    // Remove the word "about"
    return formatted.replace('about ', '');
};

const TxLog: React.FC<TxLogProps> = ({ log }) => {

    const getPriceString = (): string => {
        const price = ((Number(toEth(log.ethAmount, 18)) + Number(toEth(log.ethFeeAmount, 18))) / Number(toEth(log.coinAmount, 18))).toFixed(7);
        // Remode zero before the decimal point
        return price.replace(/^0+/, '');
    };

    return (
        <div className='TxLog'>
            <div className={log.transactionType === 'buy' ? 'text-green' : 'text-red'}>{log.transactionType}</div>
            <div>{toEth(log.ethAmount, 2)}</div>
            <div>{toEth(log.coinAmount, 2)}</div>
            <div>≈{getPriceString()}</div>
            <div>{customFormatDistanceToNow(new Date(log.lastUpdated), { addSuffix: true })}</div>
            <div className='text-clicky' onClick={(e) => openAddressExplorer(e, log.userAddress, log.chainId)}>{displayName(log.userAddress)}</div>
            <div className='text-clicky' onClick={(e) => openTxExplorer(e, log.txHash, log.chainId)}>{displayName(log.txHash)}</div>
        </div>
    );
};

export default TxLogList;
