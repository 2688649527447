import { ethers } from 'ethers';
import PuushdotSpotlightV2 from './contracts/PuushdotSpotlightV2.json';
import { toWei } from '../utils/priceUtils';

class SpotlightInstance {
    private contract: any;
    public contractAddress: string;

    constructor(contract: any, contractAddress: string) {
        this.contract = contract;
        this.contractAddress = contractAddress;
    }

    public static async createInstance(provider: ethers.BrowserProvider, contractAddress: string): Promise<SpotlightInstance> {
        const signer = await provider.getSigner();
        const contract = new ethers.Contract(contractAddress, PuushdotSpotlightV2.abi, signer);
        return new SpotlightInstance(contract, contractAddress);
    }

    public async buySpotlight(
        coinAddress: string,
    ): Promise<ethers.ContractTransaction> {
        const buyCost = toWei('100'); // 100 Eth in Wei
        try {
            const tx = await this.contract.buySpotlight(
                coinAddress,
                {
                    value: buyCost,
                }
            );
            return tx.wait();
        } catch (error) {
            console.error('Error selling coins:', error);
            throw error;
        }
    }
}

export default SpotlightInstance;
